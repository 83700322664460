@mixin filter($var) {
  filter: $var;
}

// Team and Leadership
.photo-grid {
  a {
    width: 157px;
  }
  .inner {
    @apply bg-gray-200;
  }
}
.leadership {
  .team_profile {
    a:hover {
      cursor: default;
    }
    img {
      width: 157px;
      @include filter(grayscale(0%));
    }
  }
}
// User Profile edit page

#grade_levels .controls {
  margin-left: -20px;
}
