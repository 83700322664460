// -----------------------------------------------------------------------------
// Core Typographic Theme
// -----------------------------------------------------------------------------

body {
  color: $font-color--normal;
  font-family: $font-default;
  font-weight: $font-weight--normal;
  line-height: $lh-m;
}

a {
  color: $link-color;
  text-decoration: none;
  &:hover {
    color: tw-color('primary', 600);
  }
  &:focus {
    @apply outline-none;
  }
  &:active {
    outline: 0;
  }
}

ul,
ol {
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.ck-editable {
  a {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Nunito Sans', sans-serif;
}

h5,
h6,
ol,
ul,
dl,
pre,
table,
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

// -----------------------------------------------------------------------------
// Type Scale
// -----------------------------------------------------------------------------

/* See _variables.scss for scale details */

html {
  font-size: 100%;
}

h1,
.h1 {
  font-size: $fs-xxl;
  line-height: 1.1625;
  @include breakpoint(medium) {
    font-size: $fs-xxxl;
  }
  @include breakpoint(large) {
    font-size: $fs-xxxxl;
  }
}

h2,
.h2 {
  font-size: $fs-xl;
  @include breakpoint(large) {
    font-size: $fs-xxl;
  }
}
h3,
.h3 {
  font-size: $fs-l;
  @include breakpoint(large) {
    font-size: $fs-xl;
  }
}
h4,
.h4 {
  font-size: $fs-l;
}

p,
.p,
dl,
ol,
ul,
h5,
.h5,
h6,
.h6 {
  line-height: $lh-m;
}

small,
.small {
  font-size: $fs-s;
  line-height: $lh-s;
}

.smaller {
  font-size: $fs-xs;
  line-height: $lh-xs;
}

.smallest {
  font-size: $fs-xxs;
  line-height: $lh-xxs;
}

// -----------------------------------------------------------------------------
// Typographic Styling
// -----------------------------------------------------------------------------

// Typeface Specification & Overrides

.font-fancy {
  font-family: $font-fancy;
}
.font-default {
  font-family: $font-default;
}
.font-monospace {
  font-family: $font-monospace;
}
.font-nunito-sans {
  font-family: $font-nunito-sans;
}
.font-elementary {
  @include font-report;
}
.font-secondary {
  @apply font-display tracking-wide;
}

h1,
h2 {
  font-family: $font-default;
  letter-spacing: 0px;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: $font-weight--bold;
  @apply font-default;
}
h2 {
  font-weight: 600;
  color: $font-color--strong;
}
p,
li,
dd,
small,
pre,
code,
td {
  font-weight: $font-weight--normal;
  color: $font-color--normal;
}

// Bold / Strong

strong,
.bold,
dt,
th {
  font-weight: $font-weight--bold;
  color: $font-color--strong;
}

// "Weak" text (de-emphasized)

.weak {
  color: $font-color--weak;
  font-style: italic;
}

// Monospace & Code Samples

pre {
  overflow: auto;
  word-wrap: normal;
  white-space: pre;
}
code,
.code {
  font-family: $font-monospace;
  font-size: $fs-s;
  padding: 0;
  overflow-wrap: break-word;
}

pre code {
  font-size: $fs-xxs;
}

// Text Decoration
.underline-border {
  @apply border-gray border-b border-dashed;
}

// Character Tweaks

strong,
.bold,
.font-weight-bold {
  font-weight: $font-weight--bold;
}
.regular {
  font-weight: $font-weight--normal;
}
.caps {
  text-transform: uppercase;
}

// Alignment Tweaks

.center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}

// Line Height Tweaks

.lh-xs {
  line-height: $lh-xs;
}
.lh-s {
  line-height: $lh-s;
}
.lh-m {
  line-height: $lh-m;
}
.lh-l {
  line-height: $lh-l;
}
.lh-xl {
  line-height: $lh-xl;
}
.lh-xxl {
  line-height: $lh-xxl;
}
.lh-xxxl {
  line-height: $lh-xxxl;
}
