// FIXME: Mixins are not working without this line. Asset compilation issue?
@import 'mixins';

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: inherit;
  border: 0;
  @apply border-base border-b text-base;
  float: left; // prevents the legend from displaying outside the fieldset for error styling
  + * {
    clear: both;
  }
}
label,
.label {
  @apply text-base;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.375rem;
  margin-top: 0;
  font-size: $fs-s;
  font-weight: 600;
}
input[type='search'] {
  box-sizing: border-box;
}
input[type='radio'],
input[type='checkbox'] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type='file'] {
  display: block;
}
input[type='range'] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: thin dotted;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
}
[type='text'],
[type='username'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
  @apply border-base border;
  display: block;
  width: 100%;
  padding: 7px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
  &::-moz-placeholder {
    @apply text-neutral-600 text-opacity-80;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    @apply text-neutral-600 text-opacity-80;
  }
  &::-webkit-input-placeholder {
    @apply text-neutral-600 text-opacity-80;
  }
}
input[disabled],
input[readonly],
fieldset[disabled] {
  @apply bg-gray-50 text-gray-700;
  cursor: not-allowed;
  opacity: 1;
  &:hover {
    @apply border-gray bg-gray-50 text-gray-700;
  }
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  line-height: 34px;
  line-height: 1.42857143 \0;
}

.form-box {
  margin-bottom: 15px;
}
.radio,
.checkbox {
  position: relative;
  display: block;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: normal;
}
.radio label,
.checkbox label {
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}
.radio-inline,
.checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}
input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  cursor: not-allowed;
}
.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}
.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
  color: $font-color--weak;
}

.help-block {
  display: block;
  color: $font-color--weak;
  font-size: $fs-xs;
  margin-top: $sp-xs;
  margin-bottom: $sp-m;
  font-style: italic;
}

// Custom Overrides

.control-group {
  margin-bottom: 1.25rem;
}

label,
.label {
  & + .radio,
  & + .checkbox {
    margin-top: 0;
  }
}

.checkbox,
.radio {
  & + label,
  & + .label {
    margin-top: 0.5rem;
  }
}

form.w-full {
  label {
    width: 100%;
  }
  input[type='checkbox'] + label,
  input[type='radio'] + label {
    width: auto;
  }
}
input.h1 {
  font-size: $fs-xxxxl;
  line-height: $lh-xxxl;
  font-weight: bold;
  height: auto;
  @apply text-primary;
}
input.h3 {
  @apply text-primary bg-white;
  font-size: $fs-xl;
  line-height: $lh-xxxl;
  font-weight: bold;
  height: auto;
}
select {
  appearance: none;
  background-image: url('~images/icons/caret.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: top 50% right 0.5rem;
  padding-right: 35px;
  &.select--inline {
    display: inline;
    width: auto;
  }
}

.text-group {
  @include flexbox();
  @include flexalign(stretch);
  @include flexjustify(center);
  input:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  input:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  label {
    @apply border-base bg-neutral-50 border;
    padding: 0 $sp-m;
    white-space: nowrap;
    margin: 0;
    line-height: 3;
  }
  label:first-child {
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  label:last-child {
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

// for multi-line labels with checkboxes and radio buttons
.wrapped-input {
  padding-left: 20px;
  position: relative;

  input {
    margin-left: -20px;
    position: absolute;
  }
}

// Search field for pages
.search-box {
  position: relative;
  .search-query {
    border-radius: 20px !important;
    font-size: $fs-s;
    padding: 6px 12px;
  }
  .search-submit {
    @apply bg-primary flex items-center;
    border-radius: 100%;
    border: none;
    padding: 0;
    width: 28px;
    height: 28px;
    position: absolute;
    text-align: center;
    top: 3px;
    right: 3px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover,
    &:focus {
      background-color: darken(tw-color('primary', 500), 15%);
    }
    svg {
      margin: 0;
    }
  }
}

.field-reset {
  background: url('~images/icons/field-reset.svg') no-repeat;
  border-radius: 20px;
  border: none;
  padding: 0;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

// Styles for inline error message for forms
.error--inline {
  color: #f1290e;
  font-size: $fs-xs;
  padding: 5px;
}
.form-box {
  &.field-error {
    margin-bottom: 5px;
    input {
      border-color: #f1290e;
    }
  }
}

// Styles for item selection search (with typeahead)
.search-box__results {
  @apply border border-gray-200;
  position: relative;
  top: -14px;
  .highlighted {
    background-color: rgba(236, 216, 19, 0.5);
  }
}

// custom select using dropdown
.select-dropdown {
  display: inline-block;
  width: auto;

  label {
    display: block;
  }

  .btn {
    @apply bg-white;
    text-align: left;

    .icon {
      float: right;
      margin-left: $sp-m;
    }
  }

  @include breakpoint(large) {
    display: inline-block;

    label {
      display: block;
    }
  }

  .select-dropdown__options {
    max-width: 400px; // nested to override .dropdown-pane
  }
}

.select-dropdown__option {
  @include flexbox();
  cursor: pointer;
  padding: $sp-m;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
      @apply bg-white;
    }
  }

  .icon svg {
    @apply border border-gray-700 text-white;
    border-radius: 50%;
    margin-top: 4px;
  }
}

.select-dropdown__option-description {
  padding-left: $sp-s;

  h5 {
    font-weight: normal;
    margin-bottom: 0;
  }

  .smallest {
    color: $font-color--weak;
    line-height: $lh-s;
    margin-bottom: 0;
  }
}

.select-dropdown__option--selected {
  @apply bg-base;

  .icon svg {
    color: $font-color--normal;
    border: 0;
  }
}

// datepicker customizations
.datepicker {
  table {
    tr {
      td,
      th {
        height: 30px;
        width: 30px;
        vertical-align: middle;
        &.active {
          background: rgb(var(--green-500)) !important; // need to override specifity in the library
        }
        @include breakpoint(medium down) {
          height: 44px;
          width: 44px;
        }
      }
    }
  }
}

// autocomplete list
.autocomplete {
  position: relative;
}

.autocomplete__list {
  @apply border border-gray-200 bg-white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  display: block;
  font-size: $fs-s;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  z-index: $z-dropdown;

  tbody {
    display: block;
  }

  tr,
  td {
    border: 0;
    cursor: pointer;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &:hover,
    &:active {
      @apply bg-primary-100;
    }
  }

  td {
    @apply border-b border-gray-200;
    float: left;
    max-width: 50%;
  }
}

.autocomplete__not-found {
  @apply bg-base;
  text-align: center;

  td {
    max-width: 100%;
  }

  .btn--demoted {
    padding: 0;
  }
}

// checkbox switch toggle button
.switch-toggle {
  @include flexbox();
  @include flexalign(stretch);
  label {
    @apply text-muted;
    display: block;
    font-size: $fs-xxs;
    margin-top: $sp-xs;
    text-align: center;
    margin-bottom: 0;
    @include alignself(center);
    @include flex-val(0);
    @include breakpoint(medium) {
      margin-top: 0;
      &:first-of-type {
        padding-right: $sp-s;
        @include order(-1);
      }
      &:last-of-type {
        padding-left: $sp-s;
      }
    }
  }
  .switch-toggle__button {
    @apply border-base border;
    border-radius: 2rem;
    display: inline-block;
    height: 30px;
    margin-top: 0;
    padding: 8px 22px;
    position: relative;
    transition: border-color 0.5s ease;
    appearance: none;
    cursor: pointer;
    max-width: 40px;
    @include alignself(center);
    &:before {
      @apply border-gray border bg-gray-200;
      border-radius: 1rem;
      content: '';
      display: block;
      height: 1.5rem;
      left: 2px;
      top: 2px;
      position: absolute;
      transition: all 0.1s ease-in-out;
      width: 40px;
    }
    &:after {
      @apply bg-white;
      content: '';
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.1);
      top: 4px;
      left: 3px;
      border-radius: 50%;
      transition: all 0.1s ease-in-out;
    }
    &:checked {
      &:before {
        @apply border-green-400 bg-green-400;
      }
      &:after {
        box-shadow: -2px 1px 3px 0px rgba(0, 0, 0, 0.1);
        left: 20px;
      }
    }
    &:focus,
    &:hover {
      @apply border-green-300;
      outline: none;
      transition: border-color 0.1s ease;
    }
    &[disabled] {
      opacity: 0.5;
      ~ label {
        @apply text-gray;
      }
    }
  }
  @include breakpoint(small down) {
    @include flexdirection(column);
    label:last-of-type {
      display: none;
    }
    .switch-toggle__button {
      &:checked ~ label {
        &:first-of-type {
          display: none;
        }
        &:last-of-type {
          display: inline-block;
        }
      }
    }
  }
}

// graphical checkbox
.graphical-checkbox {
  @include flexbox();
  @include flexalign(stretch);

  @include breakpoint(medium down) {
    @include flexwrap(wrap);
  }

  input {
    position: absolute;
    visibility: hidden;
  }
}

.graphical-checkbox__item {
  @apply border border-gray-200 bg-gray-50;
  border-radius: calc($border-radius / 2);
  border-bottom-width: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  margin: $sp-m;
  overflow: hidden;
  text-align: center;
  transition: all 0.2s;
  width: 100%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:focus {
    @apply border-primary-600;

    .graphical-checkbox__image {
      @apply border-primary-600;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  @include breakpoint(medium down) {
    width: calc(100% / 3 - 1.5rem); // 3 across including margin

    &:nth-child(4n) {
      margin-left: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @include breakpoint(small down) {
    width: calc(100% / 2 - 2rem); // 2 across including margin

    &:nth-child(odd) {
      margin-left: 0;
      margin-right: $sp-m;
    }

    &:nth-child(even) {
      margin-left: $sp-m;
      margin-right: 0;
    }
  }
}

.graphical-checkbox__image {
  @apply border-base bg-focus border-2;
  border-radius: 50%;
  margin: $sp-s $sp-m;
  overflow: hidden;
  transition: all 0.1s;
  width: calc(100% - 2rem);
}

.graphical-checkbox__label {
  margin-top: 0.75rem;
  padding: $sp-s;
  text-align: center;
  width: 100%;
}

.graphical-checkbox__icon {
  visibility: hidden;
  margin: -2.25rem auto 0;

  svg {
    @apply border-2 border-green-400 bg-green-400 text-white;
    border-radius: 50%;
    padding: $sp-xs;
    width: $fs-xxl;
  }
}

.graphical-checkbox__item {
  input:checked {
    ~ .graphical-checkbox__image {
      @apply border-green-400;
    }

    ~ .graphical-checkbox__label .graphical-checkbox__icon {
      visibility: visible;
    }
  }
}

// radio toggle buttons
.toggle-btn {
  margin: $sp-s 0;
  label {
    .toggle-btn__option {
      @apply border-base bg-focus text-accent inline-flex items-center justify-center space-x-2 border text-center;
      text-align: center;
      border-left-width: 0;
      padding: $sp-s $sp-m;
      margin-left: -0.25rem;
      cursor: pointer;
      transition: all 0.2s;
      font-weight: normal;
      &:hover {
        @apply text-accent-hover bg-focus-hover;
      }
      &:focus {
        @apply text-accent-hover;
      }
    }
    &:first-child .toggle-btn__option {
      @apply rounded-l-lg;
      border-left-width: 1px;
    }
    &:last-child .toggle-btn__option {
      @apply rounded-r-lg;
    }
    .icon {
      display: block;
      margin: auto;
    }
    .inline {
      display: inline;
      vertical-align: -0.1rem;
    }
    span {
      font-size: $fs-xxs;
    }
    input {
      display: none;
      &:checked + .toggle-btn__option {
        @apply bg-base text-important;
      }
    }
    .toggle-btn__option.selected {
      @apply bg-base text-important;
    }
  }
}

//---------------------
// Inline checkboxes
//---------------------
.inline-selection-wrapper {
  margin-bottom: $sp-l;

  label {
    display: block;
  }

  .checkbox {
    display: inline-block;
    margin-right: $sp-m;
  }
}

//---------------
// Date Picker
//---------------

.date-select {
  flex-flow: row wrap;

  label {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}

.date-picker_icon {
  @apply bg-primary;
  border-top-left-radius: 0.325rem;
  border-bottom-left-radius: 0.325rem;
  height: 38px;
  left: 0;
  padding-top: 0.5rem;
  position: absolute;
  top: 0;
  transition: all 0.3s ease;
  width: 36px;

  &:hover {
    @apply bg-primary-600;
  }

  svg {
    display: block;
    fill: var(--white);
    height: 1.5rem;
    margin: 0 auto;
  }
}

//print option radio buttons
.tile-container {
  position: relative;
  * {
    transition: all 0.2s ease;
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
    + .lzui-tile {
      .copy-item {
        rect,
        line {
          fill: rgb(var(--gray-200));
          stroke: rgb(var(--gray-400));
        }
      }
      .page {
        fill: rgb(var(--gray-200));
      }
      .cards {
        fill: rgb(var(--gray-400));
      }
      .details {
        fill: rgb(var(--gray-500));
      }
    }
    &:hover + .lzui-tile {
      @apply border-base-hover;
      .cards {
        fill: rgb(var(--primary-300));
      }
      .copy-item {
        rect,
        line {
          stroke: rgb(var(--primary-300));
        }
      }
    }
    &:checked + .lzui-tile {
      @apply border-base-hover bg-focus shadow;
      .copy-item {
        rect,
        line {
          fill: tw-color('green', 100);
          stroke: tw-color('green', 100);
        }
      }
      .page {
        fill: var(--white);
      }
      .cards {
        @apply fill-primary-600;
      }
      .details {
        fill: tw-color('green', 100);
      }
      h4 {
        @apply bg-green-400 text-white;
        border-radius: 0.25rem 0.25rem 0 0;
      }
      h5 {
        @apply bg-accent text-white;
        border-radius: 0.25rem 0.25rem 0 0;
      }
      p {
        @apply bg-green-400 text-white;
      }
    }
  }
  label {
    @include flexbox();
    .icon {
      align-self: center;
      line-height: 0;
      margin-right: 1rem;
      svg {
        height: 3rem;
        width: 2.25rem;
      }
    }
    .tile-text {
      align-self: center;
    }
  }

  &:has(input:focus-visible) {
    @apply ring-primary-accent rounded-md ring-2 ring-offset-2;
  }
}

// drag file forms
.filedrop {
  border: 1px dashed white;
}
.filedrop.dragover {
  @apply border-secondary bg-secondary border border-dashed;
}

// cover image upload
@include breakpoint(medium) {
  cover-image-upload,
  .cover-image-upload {
    .file-upload .file-upload__preview {
      width: 100%;
    }
  }
}

div > .v-select {
  .vs__open-indicator:before {
    @apply border-primary-800;
  }
  .vs__dropdown-toggle {
    @apply border-base bg-focus;
  }
}

// -----------------------------------------------------------------------------
// Content Separations: Line with text separator
// -----------------------------------------------------------------------------
.hr_text {
  @apply border-base border-t;
  margin-bottom: -0.7rem;

  + p {
    @apply text-muted;
    font-size: $fs-s;
    font-weight: bold;
    text-align: center;
    text-transform: lowercase;

    span {
      padding: $sp-s $sp-m;
    }
  }
}

.inset-line {
  margin-bottom: 2rem;
  margin-top: 2rem;

  .top {
    @apply border-base border;
  }

  .bottom {
    @apply border-b border-white;
  }
}

.v-select {
  .vs__search {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 1px solid transparent;
    border-left: none;
    box-shadow: none;
    color: var(--vs-search-input-color);
    flex-grow: 1;
    font-size: var(--vs-font-size);
    line-height: var(--vs-line-height);
    margin: 4px 0 0;
    max-width: 100%;
    outline: none;
    padding: 0 7px;
    width: 0;
    z-index: 1;
  }

  .vs__dropdown-menu {
    overflow-x: hidden;
  }

  .vs__dropdown-option {
    white-space: normal;
  }
}
