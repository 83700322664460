// allow scrolling of wikipage tables on smaller devices
@include breakpoint(medium down) {
  .resource__content {
    table {
      display: inline-block;
      border: 0;
      max-width: 100%;
      overflow-x: auto;
    }
  }
}

// ckeditor css

.cke_textarea_inline {
  height: fit-content;
  p > br {
    clear: both;
  }
}

$insert-medium: 61.875em;
$insert-tiny: 32.125em;

@keyframes slide-toggle {
  from {
    width: 0;
  }
  to {
    width: 850px;
  }
}

@keyframes section-slide-toggle {
  from {
    width: 0;
  }
  to {
    width: 760px;
  }
}

@keyframes icon-section-slide-toggle {
  from {
    width: 0;
  }
  to {
    width: 402px;
  }
}

@keyframes icon-slide-toggle {
  from {
    width: 0;
  }
  to {
    width: 448px;
  }
}

@keyframes tiny-slide-toggle {
  from {
    width: 0;
  }
  to {
    width: 210px;
  }
}

@keyframes card-editor-slide-toggle {
  from {
    width: 0;
  }
  to {
    width: 87px;
  }
}

.slide-toggle-enter-active,
.slide-toggle-leave-active {
  margin-left: -0.25rem;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px darken(tw-color('neutral', 200), 5%) inset;
  > label {
    display: none;
  }
  .vue-dropdown {
    display: none !important;
  }
  // this adjusts the height in a way that maintains the correct layout
  > label:first-child {
    display: inline-block !important;
    visibility: hidden;
  }
}
.slide-toggle-enter-active {
  animation: slide-toggle 0.3s;
  @include breakpoint($insert-medium down) {
    animation: icon-slide-toggle 0.3s;
  }
  @include breakpoint($insert-tiny down) {
    animation: tiny-slide-toggle 0.3s;
  }
}
.slide-toggle-leave-active {
  animation: slide-toggle 0.3s reverse;
  @include breakpoint($insert-medium down) {
    animation: icon-slide-toggle 0.3s reverse;
  }
  @include breakpoint($insert-tiny down) {
    animation: tiny-slide-toggle 0.3s reverse;
  }
}
#additional-materials-editor {
  .slide-toggle-enter-active {
    animation: icon-slide-toggle 0.3s;
    @include breakpoint($insert-tiny down) {
      animation: tiny-slide-toggle 0.3s;
    }
  }
  .slide-toggle-leave-active {
    animation: icon-slide-toggle 0.3s reverse;
    @include breakpoint($insert-tiny down) {
      animation: tiny-slide-toggle 0.3s reverse;
    }
  }
  ul.wiki-blocks {
    > li {
      border: none;
      margin-right: 20px;
      .cke_textarea_inline {
        min-width: 50px;
      }
      &:after {
        z-index: initial;
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
}

.section-accordion__body {
  .slide-toggle-enter-active {
    animation: section-slide-toggle 0.3s;
    @include breakpoint($insert-medium down) {
      animation: icon-section-slide-toggle 0.3s;
    }
    @include breakpoint($insert-tiny down) {
      animation: tiny-slide-toggle 0.3s;
    }
  }
  .slide-toggle-leave-active {
    animation: section-slide-toggle 0.3s reverse;
    @include breakpoint($insert-medium down) {
      animation: icon-section-slide-toggle 0.3s reverse;
    }
    @include breakpoint($insert-tiny down) {
      animation: tiny-slide-toggle 0.3s reverse;
    }
  }
}

.section-accordion .insert-options .label__section-insert {
  display: none;
}

.lz-content-modal__choose .wiki-blocks .empty-state--component > svg {
  display: none;
}

.lz-content-modal__choose,
.card-editor {
  .insert-options {
    .listable {
      display: none;
    }
    > label > .toggle-btn__option.section-insert {
      border-radius: 0 1rem 1rem 0 !important;
    }
    .toggle-btn-label__last {
      display: none !important;
    }
  }
  .lz-standard {
    display: inline-block;
  }
  .insert-options.slide-toggle-enter-active {
    > label {
      display: none;
    }
    animation: card-editor-slide-toggle 0.3s;
  }
  .insert-options.slide-toggle-leave-active {
    > label {
      display: none;
    }
    animation: card-editor-slide-toggle 0.3s reverse;
  }
  ul.wiki-blocks {
    min-height: 130px;
    max-height: 300px;
    overflow: scroll;
    padding-right: $sp-m;
  }
  .section-accordion__body > ul.wiki-blocks {
    max-height: unset;
    overflow: unset;
  }
}

.listable-editor {
  @include flexbox();
  @apply py-2;
  overflow: hidden;
  position: relative;
  .menu-overlay {
    position: absolute;
    height: 95%;
    width: 100%;
    z-index: $z-base + 1;
  }
  > .content,
  ul {
    flex: 1;
    min-width: 0;
  }
  &.wiki-content > .ck-editable {
    @apply overflow-auto;
  }
}

.insert-options {
  @apply bg-focus;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: inline-block;
  transform-origin: left top;
  > label:first-child {
    display: none;
  }
  .vue-dropdown {
    display: none;
  }
  > label > .toggle-btn__option.toggle-btn__last {
    border-radius: 0 1rem 1rem 0;
  }
  @include breakpoint($insert-tiny down) {
    .vue-dropdown {
      display: inline-block;
      width: auto;
      button {
        border-radius: 0 1rem 1rem 0;
        margin-left: -1px;
        margin-top: -1px;
        font-size: $fs-s;
      }
    }
    > label > .toggle-btn__option.toggle-btn__last {
      border-radius: 0;
    }
    > label.listable {
      display: none;
    }
  }
}

.block-drag-indicator {
  position: absolute;
  top: -20px;
  height: 5px;
  @apply border-primary border-t-2 border-dashed;
  cursor: grabbing;
}

.ghost-wiki-block {
  @apply border-neutral-600 bg-neutral-500 border-2 border-dashed bg-opacity-10;
  max-height: 150px;
  border-radius: $border-radius;
  align-items: end !important;
  .section-accordion__body {
    display: none !important;
  }
  .lzui-modal__close {
    display: none;
  }
}

.drag-cover {
  position: absolute;
  z-index: $z-base + 1;
  width: 100%;
  height: 100%;
}

#wiki-content {
  ul.wiki-blocks.hide-hidden {
    li.meta-hide {
      display: none;
    }
  }
}

.menu-push ul.wiki-blocks > li > .insert-wiki-block .toggle-btn label > .toggle-btn__option > span {
  @media (min-width: 80em) and (max-width: 82.63em) {
    display: none;
  }
}

ul.wiki-blocks {
  .toggle-btn > label > .toggle-btn__option.disabled {
    cursor: not-allowed;
  }
  &.moving {
    min-height: 50px;
    .insert-wiki-block {
      display: none !important;
    }
  }
  list-style: none;
  @apply border-base border;
  > li {
    @include flexbox();
    margin: 0;
    position: relative;
    min-height: 5rem;

    &.meta-insert {
      @apply border-primary-accent border-2;
      .section-accordion {
        li {
          border: none;
          margin: 0;
          &:after {
            @apply border-primary border border-dashed;
          }
        }
      }
      &:after {
        border: none;
      }
    }
    &.meta-hide {
      @apply border-gray border border-dashed bg-gray-50;
      .section-accordion {
        h2,
        li {
          @apply bg-gray-50;
        }
      }
    }

    .up-down-arrows {
      margin-left: -0.5rem;
      margin-right: 0.5rem;
      @include flexbox();
      @include flexdirection(column);
      svg {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        color: darken(tw-color('primary', 500), 10%);
        &:hover {
          color: darken(tw-color('primary', 500), 20%);
        }
      }
      svg.handle {
        cursor: grab;
      }
      svg:first-child {
        transform: rotate(180deg);
      }
    }

    &:after {
      margin-left: 35%;
      width: 30%;
      position: absolute;
      @apply border-primary-600 rounded-2xl border-t-2 border-opacity-20;
      bottom: 0;
      z-index: 0;
      content: '';
    }

    > .insert-wiki-block {
      left: 0;
      .toggle-btn {
        display: none;
        max-height: 40px;
        label {
          margin-bottom: 0;
          > .toggle-btn__option {
            padding: 0.4rem 0.8rem;
            margin: 0;
            @include flexbox();
            @include flexalign(center);
            > span {
              padding-left: 2px;
              @include breakpoint($insert-medium down) {
                display: none;
              }
            }
          }
        }
        .icon {
          display: inline-block;
        }
        .icon > svg {
          transition: all 0.3s ease;
          transform: rotate(-45deg);
        }
        label:first-child > .toggle-btn__option {
          border-bottom-left-radius: 1rem;
          border-top-left-radius: 1rem;
        }
        label:last-child > .toggle-btn__option {
          border-bottom-right-radius: 1rem;
          border-top-right-radius: 1rem;
        }
      }
      &:hover > .toggle-btn {
        display: block;
      }
      &.open {
        > .toggle-btn {
          display: block;
          .icon > svg {
            transition: all 0.3s ease;
            transform: unset;
          }
        }
      }
      position: absolute;
      width: 100%;
      min-height: 40px;
      bottom: -27px;
      @include flexbox();
      @include flexalign(flex-end);
      @include flexjustify(center);
      &.insert-wiki-block--above {
        bottom: unset;
        top: -26px;
        align-items: flex-start;
      }
    }

    &.blank-slate {
      @include flexdirection(column);
      > .insert-wiki-block {
        position: unset;
        label:nth-child(2) > .toggle-btn__option {
          border-bottom-left-radius: 1rem;
          border-top-left-radius: 1rem;
          border-left-width: 1px;
        }
      }
    }
  }
  > li:first-child {
    margin-top: 20px;
  }
  > li:last-child {
    &:after {
      display: none;
    }
  }

  ul.block-types {
    a > p {
      display: none;
    }
    &.wiki-lists > li,
    &.wiki-embeds > li {
      padding: $sp-s;
      width: unset;
    }
  }
}

.col-span-12 > ul.wiki-blocks > li:last-child > .insert-wiki-block--below > .toggle-btn {
  display: block;
}

.section-accordion__body > ul.wiki-blocks {
  border: none;
  > li:after {
    z-index: unset;
  }
  > li:last-child {
    margin-bottom: $sp-l;
  }
  > li:only-child {
    .lzui-modal__close {
      display: none;
    }
  }
}

a.cke_button_disabled {
  .cke_button_label {
    opacity: 0.3;
  }
}

// still used
#wiki-edit-feature {
  .wiki-editor-toolbar {
    max-width: unset;
  }
  .cke_editable {
    width: 100%;
    min-height: 1rem;
    h3,
    h4 {
      margin-bottom: $sp-s;
      margin-top: 2rem;
    }
    ol,
    ul {
      margin-bottom: 1em;
      padding: 0 0 0 1.75em;

      li {
        margin: 0 0 0 0.75em;
      }
    }
    p {
      margin-top: 1rem;
    }
  }
}

.wiki-editor-toolbar {
  @media (min-width: 1090px) {
    top: 0;
    max-width: 1070px; // maximum width of textarea
  }
}

.lz-content-modal__choose,
.card-editor,
#additional-materials-editor {
  .insert-wiki-block .toggle-btn label > .toggle-btn__option > span {
    display: none;
  }
}

// adjust typography spacing for wikipages
#wiki_content ~ .cke_editable {
  h3,
  h4 {
    margin-bottom: $sp-s;
    margin-top: 2rem;
  }
}
