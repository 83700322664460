// Primary Adjustments

.card .font-primary {
  .lrn_widget .lrn_response,
  #lesson-plan-preview-feature .lz-learnosity-item .lrn_response {
    ul.lrn-response-validate-wrapper > li.lrn-mcq-option,
    ul.lrn_mcqgroup > li.lrn-mcq-option {
      .lrn_contentWrapper,
      .lrn_singleRow {
        @apply text-lg #{!important};
      }
    }
  }
}

// Classes backgrounds

body {
  &[data-grade-level='K'],
  &[data-grade-level='1'],
  &[data-grade-level='2'],
  &[data-grade-level='3'] {
    &::-webkit-scrollbar {
      @apply w-6;
    }
    &::-webkit-scrollbar-track {
      @apply bg-neutral-400 rounded-2xl;
    }
    &::-webkit-scrollbar-thumb {
      @apply bg-accent rounded-2xl transition-colors;
    }
    &::-webkit-scrollbar-thumb:hover {
      @apply bg-accent-hover;
    }
  }
}
