// -----------------------------------------------------------------------------
// General
// -----------------------------------------------------------------------------

.lzui {
  @media #{$medium-up} {
    @include flexalign(stretch);
    @include flexbox();
  }
}
#footnotes_debug {
  display: none;
}

.lzui-section {
  display: none;
}

.lzui-sidebar {
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.15);
}

.lzui-title {
  border-bottom: 1px solid rgba($white, 0.25);
  margin: 0 1rem 1.5rem;
}

.lzui-logo {
  @apply border-2 border-white bg-white;
  border-radius: 50%;
  display: block;
  height: 3rem;
  margin: 0 auto;
  width: 3rem;
  img {
    width: 100%;
  }
}

// Override the Premium Gate only in LZUI, so it isn't a modal
.lzui__premium-gate {
  .premium-gate__screen {
    display: none;
  }
  .premium-gate__modal {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    left: auto;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-top: 2rem;
    position: static;
  }
}

// Highlight.js syntax blocks. TO DO: rename this to be more semantic.
.highlight {
  background: #edf6fc; /* 10% of brand blue */
}

// Ugh, horrible name. TO DO: rename this & change across site. It's basically a box, and sometimes is used along with .highlight (which is another bad name)
.style_box {
  border-radius: 6px;
  border-top-left-radius: 0;
  border: 1px solid #d4dcdf;
  box-sizing: border-box;
  margin-bottom: $sp-m;
  overflow: auto;
  padding: $sp-l;
}

.code__highlighted {
  background: yellow;
}

.code__replace {
  background: transparentize(#0fff00, 0.5);
}

// LZUI navigation
.lzui-nav a {
  @apply text-white;
  display: inline-block;
  transition: all 0.2s;
  width: 100%;
  &:hover,
  &:active,
  &:focus,
  &.active {
    background: darken(tw-color('primary', 500), 15%);
    color: white;
    outline: 0;
  }
}

// -----------------------------------------------------------------------------
// Spacing
// -----------------------------------------------------------------------------

.codeblock {
  border-radius: 4px;
  border: solid 1px #def2fb;
  @apply text-pink;
  padding: 0 4px;
  background: #f8fdff;
}

.property {
  @apply border border-gray-200;
  padding: 1rem;

  h2 {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  a {
    @apply bg-green;
    color: white;
    display: inline-block;
    padding: 1rem;
  }
}

.property-pinkbg {
  background: #f1a3c9;

  .property-example-content {
    background: #fff;
    display: inline-block;

    p {
      border-bottom: 16px solid #c9ebf9;
      display: inline-block;
    }

    .buttons-margin {
      background: #c9ebf9;
    }
  }
}
.property-bluebg {
  background: #c9ebf9;
}

// -----------------------------------------------------------------------------
// Labels
// -----------------------------------------------------------------------------

.colorkey {
  padding: 0;

  h4 {
    display: inline;
    margin-right: 1rem;
  }

  .key-padding:before,
  .key-margin:before,
  .key-all:before,
  .key-vertical:before,
  .key-top:before,
  .key-bottom:before,
  .key-horizontal:before,
  .key-left:before,
  .key-right:before {
    content: '';
    display: inline-block;
    height: 16px;
    margin-right: 0.5rem;
    vertical-align: middle;
    width: 16px;
  }

  .key-padding:before {
    background: #f1a3c9;
  }

  .key-margin:before,
  .key-bottom:before {
    background: #c9ebf9;
  }

  .key-all:before .key-bottom:before {
    background: #c9ebf9;
  }

  .key-all:before {
    @apply bg-pink;
  }

  .key-vertical:before {
    @apply bg-primary-600;
  }

  .key-top:before {
    background: tw-color('primary', 600);
  }

  .key-horizontal:before {
    background: #aaaaaa;
  }

  .key-left:before {
    background: #e6e6e6;
  }

  .key-right:before {
    background: #4a4a4a;
  }
}

.colorkey-all,
.colorkey-vertical,
.colorkey-horizontal {
  margin-bottom: 0.5rem;
}

.direction .colorkey {
  display: inline-block;
  height: 100%;

  @media #{$medium-up} {
    width: 33.333%;
  }

  h4 {
    display: block;
    line-height: 0.5rem;
  }
}

// --------------------------------
// Amount
// --------------------------------

.amount-box {
  margin-bottom: 1.5rem;

  @media #{$large-up} {
    margin-bottom: 0rem;
  }

  .amount-spacing {
    background-clip: padding-box;
    background: #c9ebf9;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    display: inline-block;
    height: 2rem;
  }

  p {
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}

.spacing-size-list {
  display: inline-block;
  margin-bottom: 0;

  li {
    list-style: none;
    margin-bottom: 0.5rem;
  }

  li:last-of-type {
    margin-bottom: 0;
  }
}
