.legend {
  .label {
    display: block;
    border-bottom-style: solid;
    border-bottom-width: $border-width;
    border-bottom-color: $border-color;
    padding-bottom: $sp-s;
    @include breakpoint(large) {
      border-bottom-width: 0;
      border-right-style: solid;
      border-right-width: $border-width;
      border-right-color: $border-color;
      margin-right: $sp-s;
      padding-bottom: 0;
      padding-right: $sp-s;
    }
  }
}

.legend-item {
  position: relative;
  margin-right: $sp-s;
  margin-left: $sp-s;
  padding: 0 0 0 1.25rem;
  vertical-align: top;
  span {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 7px;
    left: 0;
    svg {
      display: block;
      height: 16px;
      width: 16px;
    }
  }
  .results-score--low,
  .results-score--medium,
  .results-score--high,
  .results-score--needs-grading,
  .results-score--not-attempted {
    left: -5px;
    top: 3px;
  }
  p {
    display: inline-block;
    font-size: $fs-s;
    margin-bottom: 0;
  }
  .legend-tip {
    @apply text-gray;
    display: block;
    font-size: $fs-xxs;
    margin-top: -$sp-xs;
  }
}

.legend-scoring-range {
  margin: $sp-s 0;
  padding: $sp-s 0;
  border-bottom: $border-color 0 solid;
  border-left: $border-color 0 solid;
  border-top: $border-color 0 solid;
}

@include breakpoint(large) {
  .legend .label,
  .legend-item,
  .legend-scoring-range {
    display: inline-block;
  }
  .legend-scoring-range {
    margin: 0 $sp-s;
    padding: 0 0.5rem 0 1rem;
    border-bottom: $border-color 0 solid;
    border-left: $border-color $border-width solid;
    border-top: $border-color 0 solid;
  }
}

//Learnosity colors
.legend-color--answered,
.legend-color--constructed-response {
  @apply bg-primary;
}
.legend-color--unanswered {
  @apply bg-neutral-200;
}
.legend-color--low {
  @apply bg-red-300;
}
.legend-color--medium {
  background-color: $amber;
}
.legend-color--high {
  background-color: $green-ui;
}
.legend-color--clever-section {
  background: url('~images/icons/sis-class.svg');
}
.legend-color--classes {
  background: url('~images/icons/class.svg');
}
.legend-color--correct {
  @apply bg-green-300;
}
.legend-color--partial {
  @apply bg-yellow-300;
}
.legend-color--incorrect {
  @apply bg-red-300;
}
.legend-color--not-attempted {
  @apply bg-gray-200;
}
.legend-color--not-marked {
  @apply bg-gray;
}
.legend-color--complete {
  @apply bg-white;
  border: 1px $border-color solid;
}
.legend-color--needs-grading {
  background: url('~images/icons/needs-grading.svg');
  border-radius: 0 !important;
}
