.results-score {
  border-radius: 50%;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  transition: all 0.2s;
  width: 40px;
  .icon-l {
    margin-left: 3px;
    margin-top: 1px;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: -0.35rem;
  }
  > span {
    position: relative;
  }
  span {
    top: -1px;
  }
}

.results--student {
  .results-score {
    svg {
      margin-top: 0.4rem;
    }
    &.results-score--high {
      @apply bg-green-100;
      svg {
        fill: var(--white);
        stroke: tw-color('green', 500);
      }
    }
    &.results-score--low {
      @apply bg-red-100;
      svg {
        fill: var(--white);
      }
    }
  }
}

a .results-score {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);

  &.results-score--unanswered {
    box-shadow: none;
  }

  &:hover {
    &.results-score--high {
      @apply bg-green-200 text-green-500 shadow-none;
    }
    &.results-score--medium {
      @apply bg-yellow-300 text-yellow-600  shadow-none;
    }
    &.results-score--low {
      @apply bg-red-200 text-red-500 shadow-none;
    }
    &.results-score--unanswered {
      @apply bg-neutral-200;
    }
    &.results-score--answered,
    &.results-score--constructed-response {
      @apply bg-primary-600 shadow-none;
    }
  }
}
.score-dot {
  height: 24px;
  position: absolute;
  width: 24px;

  svg {
    display: block;
  }
}

.results-score--high {
  @apply bg-green-100;
  svg.score-dot {
    stroke: tw-color('green', 500);
  }
}
.results-score--medium {
  @apply bg-yellow-300;
  svg.score-dot {
    stroke: tw-color('yellow', 600);
  }
}
.results-score--low {
  @apply bg-red-100 text-red-500;
  svg.score-dot {
    stroke: tw-color('red', 500);
  }
}
.results-score--no-score {
  svg {
    fill: tw-color('neutral', 100);
  }
}
.results-score--needs-grading {
  @apply bg-primary-200;
  svg {
    @apply stroke-primary-600;
  }
}

.results-score--unanswered,
.results-score--not-attempted {
  .score-completion {
    background-color: rgba(var(--neutral-200), var(--tw-text-opacity));
  }
}
.results-score--answered,
.results-score--constructed-response {
  svg {
    fill: rgb(var(--blue-500));
  }
}

.results--small {
  .results-score {
    width: 2rem;
    height: 2rem;
    line-height: 2.3;
    .score-dot {
      height: 100%;
      width: 100%;
      svg {
        display: block;
        height: 100%;
        margin-top: 0;
        width: 100%;
      }
    }
  }
  &.results--student {
    .results-score {
      svg {
        margin-top: 4px;
      }
    }
  }
}

.assignment-progress__block {
  .results-score--unanswered,
  .results-score--not-attempted {
    .score-dot {
      svg {
        @apply border-gray border;
        fill: tw-color('gray', 100);
        box-shadow: inset 0 8px 3px 0 rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        transition: all 0.2s ease;
        &:hover {
          border-color: darken(tw-color('gray', 400), 10%);
          fill: rgb(var(--gray-50));
        }
      }
    }
  }
}

.rating-indicator {
  &--low:after {
    @apply bg-red-300;
  }
  &--medium:after {
    @apply bg-yellow-300;
  }
  &--high:after {
    @apply bg-green-300;
  }

  &--left:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.3rem;
    height: 100%;
  }

  &--bottom:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.3rem;
    width: 100%;
  }
}

.rounded .rating-indicator--left:after {
  transition: border-bottom-left-radius 0.1s ease 0.3s;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.rounded.open .rating-indicator--left:after {
  transition: border-bottom-left-radius 0s ease;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: 0;
}
