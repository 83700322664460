// -----------------------------------------------------------------------------
// accordion
// -----------------------------------------------------------------------------

@media print {
  .section-accordion__body[style*='display: none'] {
    display: block !important;
  }
  .closed svg {
    --tw-rotate: 90deg !important;
  }
}
