ul.card-types {
  @include flexbox();
  flex-wrap: wrap;
  list-style: none;
  text-align: center;
  > li {
    width: calc(100% / 5);
  }
}

li.has-update {
  padding-bottom: 5rem !important;
  li.has-update {
    padding-bottom: 1rem !important;
  }
}

#additional-materials-editor {
  border: none;
  box-shadow: none;
  > ul.sorting > li:hover .btn--insert {
    display: none;
  }
  > ul > li {
    border-radius: $border-radius;
    position: relative;
    &.dragging:hover .btn--insert {
      display: none;
    }
    &:hover .btn--insert {
      display: flex;
    }
    span.handle {
      cursor: grab;
    }
    .btn--insert {
      display: none;
      box-shadow: none;
      z-index: $z-dropdown;
      position: absolute;
      padding: 0;
      border-radius: 100%;
      bottom: -16px;
      right: 50%;
    }
    &.meta-insert {
      @apply border-primary-accent border-2;
    }
    &.meta-hide {
      @apply border-gray border border-dashed bg-gray-50;
    }
    &.sortable-ghost {
      opacity: 0.5;
    }
    .fsp-notifications__container {
      top: -80px;
    }
  }
}

.fsp-button--authgoogle svg {
  display: inline-block;
}

.update-notif {
  @include flexjustify(space-between);
  @include flexalign(center);
  @include flexdirection(row);
  @include flexwrap(wrap);
  position: absolute;
  bottom: 0;
  left: 0;
  background: transparentize(tw-color('blue', 500), 0.94);
  border-top: 1px solid transparentize(tw-color('blue', 500), 0.6);
  > div {
    .icon svg {
      margin-top: -0.15rem;
      margin-bottom: -0.15rem;
    }
  }
  > p {
    @include breakpoint(small down) {
      margin-left: 3.5em;
    }
  }
}
