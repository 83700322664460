@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/***** FIXME: These are the reset overrides needed to use Preflight but not break the current design *****/
@layer base {
  body {
    font-family: 'Nunito Sans', 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
    line-height: 1.6;
    accent-color: rgb(var(--primary-accent));
  }

  a {
    @apply text-accent;
  }

  a:hover {
    @apply text-accent-hover;
  }

  h1,
  h3,
  h4,
  h5 {
    @apply font-bold;
  }

  h2 {
    @apply font-semibold;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply my-6;
  }

  h1 {
    @apply text-4xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-base;
  }

  h1,
  h2,
  h3 {
    @apply text-important;
  }

  h4,
  h5,
  p,
  li,
  dd,
  small,
  pre,
  code,
  td {
    @apply text-base;
  }

  strong,
  .bold,
  dt,
  th {
    @apply text-black;
  }

  .weak {
    @apply text-muted;
  }

  select {
    @apply py-1 pl-2 pr-9;
  }

  textarea {
    @apply px-3 py-2;
  }

  button,
  button:focus {
    @apply outline-none;
  }

  ol {
    @apply list-decimal;
  }

  *:focus:not(:focus-visible) {
    @apply outline-none ring-0;
  }

  *:focus-visible {
    @apply ring-primary-accent ring-2 ring-offset-2;
  }

  hr {
    @apply border-neutral-500 mt-4 rounded-full border-t;
  }

  table {
    border-collapse: separate;
  }

  blockquote {
    @apply border-base bg-focus mb-4 ml-3 rounded-r-2xl border-l-4 pb-5 pl-3 pt-1;
  }
}

@layer utilities {
  .scroll-smooth {
    scroll-behavior: smooth;
  }

  .animate-iterations-1 {
    --tw-animate-iterations: 1;
  }

  .animate-iterations-2 {
    --tw-animate-iterations: 2;
  }

  .animate-iterations-3 {
    --tw-animate-iterations: 3;
  }

  .animate-iterations-infinite {
    --tw-animate-iterations: infinite;
  }
}

/* FIXME: Remove this once we stop using important in Tailwind. */
/* This ensures v-show's inline display: none doesn't get overridden by Tailwind classes. */
[style*='display: none'] {
  display: none !important;
}

/* FIXME: Tailwind 3 classes - remove once upgraded */
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
