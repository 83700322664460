//------------------------------------------------------
// Speech and thought bubbles for slides
// -----------------------------------------------------

.dialogue {
  @apply bg-inverted;
  border-radius: $border-radius;
  display: inline-block;
  min-height: 3rem;
  padding: $sp-m;
  position: relative;

  p {
    margin-bottom: 0;
    margin-top: $sp-m;

    &:first-child {
      margin-top: 0 !important;
    }
  }

  &.dialogue--speech {
    @apply bg-inverted border-inverted;

    &:after,
    &:before {
      border: solid transparent;
      content: ' ';
      height: 0;
      left: 50%;
      pointer-events: none;
      position: absolute;
      top: 100%;
      width: 0;
    }

    li {
      @apply text-inverted;
    }

    &.dialogue--bottom-center,
    &.dialogue--bottom-right,
    &.dialogue--bottom-right-in,
    &.dialogue--bottom-right-out,
    &.dialogue--bottom-left,
    &.dialogue--bottom-left-in,
    &.dialogue--bottom-left-out,
    &.dialogue--right {
      margin-bottom: 1.75rem;

      &:after {
        border-color: transparent;
        border-top-color: rgb(var(--primary-600));
        border-width: 1rem;
        margin-left: -1rem;
      }

      &:before {
        border-color: transparent;
        border-top-color: rgb(var(--primary-600));
        border-width: calc(1rem + 1px);
        margin-left: calc(-1rem - 1px);
      }
    }

    &.dialogue--bottom-right,
    &.dialogue--bottom-right-in,
    &.dialogue--bottom-left-out {
      &:after,
      &:before {
        border: 20px solid transparent;
        border-bottom: 0;
        border-left: 0;
      }

      &:after {
        border-top-color: rgb(var(--primary-600));
      }

      &:before {
        border-top-color: rgb(var(--primary-600));
        border-width: 22px;
      }
    }

    &.dialogue--bottom-left,
    &.dialogue--bottom-left-in,
    &.dialogue--bottom-right-out {
      &:after,
      &:before {
        border: 20px solid transparent;
        border-bottom: 0;
        border-right: 0;
      }

      &:after {
        border-top-color: rgb(var(--primary-600));
      }

      &:before {
        border-top-color: rgb(var(--primary-600));
        border-width: 22px;
      }
    }

    &.dialogue--bottom-left,
    &.dialogue--bottom-left-in,
    &.dialogue--bottom-left-out {
      &:after,
      &:before {
        left: 25px;
      }
    }

    &.dialogue--bottom-right,
    &.dialogue--bottom-right-in,
    &.dialogue--bottom-right-out {
      &:after,
      &:before {
        left: auto;
      }

      &:after {
        right: 25px;
      }

      &:before {
        right: 24px;
      }
    }

    &.dialogue--top-center,
    &.dialogue--top-right,
    &.dialogue--top-right-in,
    &.dialogue--top-right-out,
    &.dialogue--top-left,
    &.dialogue--top-left-in,
    &.dialogue--top-left-out,
    &.dialogue--left {
      margin-top: 1.75rem;

      &:after,
      &:before {
        bottom: 100%;
        left: 50%;
        top: auto;
      }

      &:after {
        border-color: transparent;
        border-bottom-color: rgb(var(--primary-600));
        border-width: 1rem;
        margin-left: -1rem;
      }

      &:before {
        border-color: transparent;
        border-bottom-color: rgb(var(--primary-600));
        border-width: calc(1rem + 1px);
        margin-left: calc(-1rem - 1px);
      }
    }

    &.dialogue--top-right,
    &.dialogue--top-right-in,
    &.dialogue--top-left-out {
      &:after,
      &:before {
        border: 20px solid transparent;
        border-left: 0;
        border-top: 0;
      }

      &:after {
        border-bottom-color: rgb(var(--primary-600));
      }

      &:before {
        border-bottom-color: rgb(var(--primary-600));
        border-width: 22px;
      }
    }

    &.dialogue--top-left,
    &.dialogue--top-left-in,
    &.dialogue--top-right-out {
      &:after,
      &:before {
        border: 20px solid transparent;
        border-right: 0;
        border-top: 0;
      }

      &:after {
        border-bottom-color: rgb(var(--primary-600));
      }

      &:before {
        border-bottom-color: rgb(var(--primary-600));
        border-width: 22px;
      }
    }

    &.dialogue--top-right,
    &.dialogue--top-right-in,
    &.dialogue--top-right-out {
      &:after,
      &:before {
        left: auto;
      }

      &:after {
        right: 25px;
      }

      &:before {
        right: 24px;
      }
    }

    &.dialogue--top-left,
    &.dialogue--top-left-in,
    &.dialogue--top-left-out {
      &:after,
      &:before {
        left: 25px;
      }
    }

    p {
      color: var(--white) !important;
    }
  }

  &.dialogue--thought {
    @apply bg-orange-50 border-orange border;
    border-radius: 5 * $border-radius;

    &:before,
    &:after {
      background: url('~images/misc/thought-bubble-tail.svg') no-repeat;
      background-size: contain;
      content: '';
      display: block;
      position: absolute;
      z-index: 10;

      @media print {
        @apply bg-orange border-orange-800 border;
        border-radius: 50%;
      }
    }

    &:before {
      height: 1.75rem;
      width: 2.5rem;
    }

    &:after {
      height: 0.75rem;
      width: 1.25rem;
    }

    &.dialogue--bottom-right,
    &.dialogue--bottom-right-in,
    &.dialogue--bottom-right-out,
    &.dialogue--bottom-center,
    &.dialogue--bottom-left,
    &.dialogue--bottom-left-in,
    &.dialogue--bottom-left-out,
    &.dialogue--right {
      margin-bottom: 1.75rem;

      &:before {
        bottom: -1rem;
      }

      &:after {
        bottom: -1.5rem;
      }
    }

    &.dialogue--bottom-center,
    &.dialogue--right {
      &:before {
        left: calc(50% - 1.5rem);
      }

      &:after {
        left: calc(50% - 0.9rem);
      }
    }

    &.dialogue--bottom-right,
    &.dialogue--bottom-right-in,
    &.dialogue--bottom-right,
    &.dialogue--bottom-right-out {
      &:before {
        right: 1rem;
      }

      &:after {
        right: 3rem;
      }
    }

    &.dialogue--bottom-right-out {
      &:after {
        right: 0.5rem;
      }
    }

    &.dialogue--bottom-left,
    &.dialogue--bottom-left-in,
    &.dialogue--bottom-left-out {
      &:before {
        left: 1rem;
      }

      &:after {
        left: 3rem;
      }
    }

    &.dialogue--bottom-left-out {
      &:after {
        left: 0.5rem;
      }
    }

    &.dialogue--top-center,
    &.dialogue--top-right,
    &.dialogue--top-right-in,
    &.dialogue--top-right-out,
    &.dialogue--top-left,
    &.dialogue--top-left-in,
    &.dialogue--top-left-out,
    &.dialogue--left {
      margin-top: 1.75rem;

      &:before {
        top: -1rem;
      }

      &:after {
        top: -1.5rem;
      }
    }

    &.dialogue--top-center,
    &.dialogue--left {
      &:before {
        left: calc(50% - 1.5rem);
      }

      &:after {
        left: calc(50% - 0.75rem);
      }
    }

    &.dialogue--top-right,
    &.dialogue--top-right-in,
    &.dialogue--top-right-out {
      &:before {
        right: 1rem;
      }

      &:after {
        right: 3rem;
      }
    }

    &.dialogue--top-right-out {
      &:after {
        right: 0.25rem;
      }
    }

    &.dialogue--top-left,
    &.dialogue--top-left-in,
    &.dialogue--top-left-out {
      &:before {
        left: 1rem;
      }

      &:after {
        left: 3rem;
      }
    }

    &.dialogue--top-left-out {
      &:after {
        left: 0.25rem;
      }
    }
  }
  p {
    @apply text-base;
  }
}

//------------------------------------------------------
// Exclude this treatment when ordering slides
// .slide__container is applied when viewing and normal editing but not ordering
// -----------------------------------------------------

.slide__container .dialogue {
  &.dialogue--speech {
    &.dialogue--right {
      margin-bottom: auto;
      margin-right: 1rem;

      &:after,
      &:before {
        border: 20px solid transparent;
        border-bottom: 0;
        border-right: 0;
        left: 100%;
        margin-left: auto;
        top: 50%;
      }

      &:after {
        border-left-color: rgb(var(--primary-600));
        margin-right: -1rem;
        margin-top: -0.5rem;
      }

      &:before {
        border-left-color: rgb(var(--primary-600));
        border-width: 22.2px;
        margin-top: calc(-0.5rem - 1px);
      }
    }

    &.dialogue--left {
      margin-left: 1rem;
      margin-top: auto;

      &:after,
      &:before {
        border: 20px solid transparent;
        border-bottom: 0;
        border-left: 0;
        left: auto;
        right: 100%;
        top: 50%;
      }

      &:after {
        border-right-color: rgb(var(--primary-600));
        margin-left: -1rem;
        margin-top: -0.5rem;
      }

      &:before {
        border-right-color: rgb(var(--primary-600));
        border-width: 22.2px;
        margin-top: calc(-0.5rem - 1px);
      }
    }
  }

  &.dialogue--thought {
    &.dialogue--left,
    &.dialogue--right {
      margin-bottom: 1rem;
      margin-top: auto;

      &:before,
      &:after {
        left: auto;
        top: auto;
      }

      &:before {
        bottom: calc(50% - 1rem);
      }

      &:after {
        bottom: calc(50% - 0.5rem);
      }
    }

    &.dialogue--left {
      margin-left: 3.4rem;

      &:before {
        left: -2rem;
      }

      &:after {
        left: -3.4rem;
      }
    }

    &.dialogue--right {
      margin-right: 3.4rem;

      &:before {
        right: -2rem;
      }

      &:after {
        right: -3.4rem;
      }
    }
  }
}
