.nextgen-item,
.nextgen-question {
  svg {
    display: inline; // overrides the tailwind preflight styling
  }
  th * {
    color: inherit;
  }
}
.font-elementary {
  .nextgen-item {
    .ngie.ngie-items-api .question-text-wrapper {
      font-family: $font-report;
    }
  }
}
.font-secondary {
  .nextgen-item {
    .ngie.ngie-items-api .question-text-wrapper {
      font-family: $font-nunito-sans;
    }
  }
}

.font-elementary {
  .nextgen-item .ngie.ngie-items-api {
    .ngie-single-select,
    .mcq-label {
      @apply text-lg;
    }
  }
}
