// --------------------------------
// Login pages
// --------------------------------

#authentication {
  #header > span,
  .header__navigation,
  .header__user-navigation,
  #header a[data-toggle-menu] {
    display: none !important;
  }

  #header {
    &:before {
      height: 0;
      width: 0;
    }

    .search {
      background: transparent;
    }

    @include breakpoint(large) {
      .logo {
        padding-left: 1.5rem;
      }

      .search {
        padding: 2rem 0;

        .search-box {
          float: right;
          margin-right: 1.5rem;
          width: 75%;
        }
      }

      .search,
      .logo {
        max-width: 550px;
      }
    }
  }
}

// --------------------------------
// Embed view navigation
// --------------------------------

.is_full {
  .embed-header {
    display: none;
  }
}

//fix to embed code overflowing container in IE and Edge
#embed {
  overflow-wrap: break-word;
  overflow: auto; //fix to overflow issue in Edge
}

// --------------------------------
// LTI navigation
// --------------------------------

#content-selection-modal {
  #footer {
    display: none;
  }
}

// --------------------------------
// Banner for announcements
// --------------------------------

.banner {
  @include flex-val(100%);
  @include order(-1);

  @media print {
    display: none;
  }
}

// --------------------------------
// Pagination
// --------------------------------

.pagination {
  margin-bottom: $sp-m;
  padding: 0;
  text-align: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
  }

  a {
    @apply text-gray-500;
    padding: 7px;
    transition: color 300ms ease-out;

    &:hover {
      @apply text-gray-800;
    }
  }

  .next a,
  .prev a,
  .first a,
  .last a {
    @apply text-gray;
  }

  .page {
    a {
      background-color: transparent;
      @apply text-primary;
    }
  }

  span {
    display: inline-block;
    position: relative;

    a {
      background-color: transparent;
      display: block;
      font-size: 1rem;
      padding: 7px;
      transition: color 300ms ease-out;

      &:hover {
        @apply text-gray-700;
        background-color: transparent;
      }
    }
  }

  span.current.page {
    @apply bg-gray-200 text-gray-700;
    background-color: transparent;
    border-radius: 2px;
    cursor: default;
    font-weight: 700;
    height: auto;
    padding: $sp-s;
    width: auto;
  }

  span.first,
  span.last,
  .disabled {
    display: none;
  }
}
