.loading {
  &:not(.v-select) {
    width: 2rem;
    transition: all 2s;
    margin: 0 auto;
  }
  &.loading--inline {
    display: inline-block;
    margin-right: 0.5rem;
    text-align: center;
    vertical-align: -0.7rem;
  }
  &.loading--small {
    width: 1.25rem;
    &.loading--inline {
      vertical-align: -0.3rem;
      margin-right: 0.25rem;
    }
    .loading__circle {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  &.loading--large {
    width: 100%;
    text-align: center;
    .loading__circle {
      width: 4rem;
      height: 4rem;
    }
  }
  .loading__circle {
    width: 2rem;
    height: 2rem;
    animation: rotate 1.5s linear infinite;
    transform-origin: center center;
    .loading__inner {
      animation: loading-color 7.5s ease-in-out infinite;
      stroke: {
        dashoffset: 0;
        dasharray: 115, 200;
        width: 2;
        miterlimit: 10;
        linecap: round;
      }
      stroke: rgb(var(--orange-500));
      fill: transparent;
      transition: stroke-color 1s;
    }
  }
  &.loading--monochrome {
    .loading__circle {
      .loading__inner {
        animation: none;
      }
    }
  }
  &.loading-spinner__overlay {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.95);
    text-align: center;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: $z-modal + 1; // needs to appear over all content, including modals, to block clicking on buttons
    top: 0;
    left: 0;
    .loading__inner {
      stroke-width: 2.5;
    }
    .loading__message {
      width: 100%;
      text-align: center;
      @include center-align;
      p {
        font-size: $fs-xl;
        color: inherit;
        text-shadow: inherit;
      }
      .loading__circle {
        width: 10rem;
        height: 10rem;
      }
    }
  }
  &.loading-spinner__cover {
    position: absolute;
    border-radius: $border-radius;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    .loading__message {
      p {
        font-size: inherit;
      }
      .loading__circle {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

.btn--updating,
.btn--updating:hover {
  .icon {
    animation: rotate 1s linear infinite;
    transform-origin: center center;
    display: block;
    float: left;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    .loading__circle {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: 0;
      display: inline-block;
      stroke: {
        dashoffset: 0;
        dasharray: 115, 200;
        width: 2;
        miterlimit: 10;
        linecap: round;
      }
      stroke: rgb(var(--gray-400));
      fill: transparent;
    }
  }
}

.progress-bar {
  @apply bg-gray-200;
  width: 65%;
  height: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  .completion-bar {
    border-radius: 1rem;
    height: 100%;
    &.loading-background {
      animation: loading-background 5s ease-in-out infinite;
    }
  }
}

@keyframes loading-background {
  100%,
  0% {
    @apply bg-primary-200;
  }
  50% {
    @apply bg-primary-400;
  }
  75% {
    @apply bg-primary-600;
  }
}

@keyframes loading-color {
  100%,
  0% {
    stroke: rgb(var(--primary-200));
  }
  25% {
    stroke: rgb(var(--primary-400));
  }
  38% {
    stroke: rgb(var(--primary-600));
  }
  50% {
    stroke: rgb(var(--primary-700));
  }
  67% {
    stroke: rgb(var(--primary-600));
  }
  75% {
    stroke: rgb(var(--primary-400));
  }
}
