/* Base16 Atelier Lakeside Light - Theme */
/* by Bram de Haan (http://atelierbram.github.io/syntax-highlighting/atelier-schemes/lakeside/) */
/* Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16) */
/* https://github.com/jmblog/color-themes-for-highlightjs */

/* Atelier Lakeside Light Comment */
.hljs-comment,
.hljs-title {
  color: #5a7b8c;
}

/* Atelier Lakeside Light Red */
.hljs-variable,
.hljs-attribute,
.hljs-tag,
.hljs-regexp,
.ruby .hljs-constant,
.xml .hljs-tag .hljs-title,
.xml .hljs-pi,
.xml .hljs-doctype,
.html .hljs-doctype,
.css .hljs-id,
.css .hljs-class,
.css .hljs-pseudo {
  color: #49bcec;
}

/* Atelier Lakeside Light Orange */
.hljs-number,
.hljs-preprocessor,
.hljs-pragma,
.hljs-built_in,
.hljs-literal,
.hljs-params,
.hljs-constant {
  color: #935c25;
}

/* Atelier Lakeside Light Yellow */
.hljs-ruby .hljs-class .hljs-title,
.css .hljs-rules .hljs-attribute {
  color: #1275a1; /* dark brand blue */
}

/* Atelier Lakeside Light Green */
.hljs-string,
.hljs-value,
.hljs-inheritance,
.hljs-header,
.ruby .hljs-symbol,
.xml .hljs-cdata {
  color: #1275a1; /* dark brand blue */
}

/* Atelier Lakeside Light Aqua */
.css .hljs-hexcolor {
  color: #2d8f6f;
}

/* Atelier Lakeside Light Blue */
.hljs-function,
.python .hljs-decorator,
.python .hljs-title,
.ruby .hljs-function .hljs-title,
.ruby .hljs-title .hljs-keyword,
.perl .hljs-sub,
.javascript .hljs-title,
.coffeescript .hljs-title {
  color: #257fad;
}

/* Atelier Lakeside Light Purple */
.hljs-keyword,
.javascript .hljs-function {
  color: #5d5db1;
}

.hljs {
  display: block;
  /*background: #ebf8ff;*/
  color: #516d7b;
  padding: 0;
}

.coffeescript .javascript,
.javascript .xml,
.tex .hljs-formula,
.xml .javascript,
.xml .vbscript,
.xml .css,
.xml .hljs-cdata {
  opacity: 0.5;
}
