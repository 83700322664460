// This file provides breakpoint functionality as used in the Foundation framework

// LZ Specifics

// matched w/ tailwind's breakpoint values
$breakpoints: (
  small: 0,
  medium: 48em,
  // 768px
  large: 64em,
  // 1024px
  xlarge: 80em,
  // 1280px
  xxlarge: 96em,
  // 1536
) !default;

$screen: 'only screen';

$landscape: '#{$screen} and (orientation: landscape)';
$portrait: '#{$screen} and (orientation: portrait)';

$small-up: $screen;
$medium-up: '#{$screen} and (min-width:#{map-get($breakpoints, "medium")})';
$large-up: '#{$screen} and (min-width:#{map-get($breakpoints, "large")})';
$xlarge-up: '#{$screen} and (min-width:#{map-get($breakpoints, "xlarge")})';
$xxlarge-up: '#{$screen} and (min-width:#{map-get($breakpoints, "xxlarge-up")})';

$medium-down: '#{$screen} and (max-width:#{map-get($breakpoints, "medium")})';
$small-down: '#{$screen} and (max-width:#{map-get($breakpoints, "small")})';

$small: $small-up;
$medium: $medium-up;
$large: $large-up;

$-breakpoints-keys: small, medium, large, xlarge, xxlarge;
$print-breakpoint: large !default;
$-zf-zero-breakpoint: small !default;

// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source
//
// BREAKPOINT MIXIN FUNCTIONS
//

@function -zf-map-next($map, $key) {
  // Store the keys of the map as a list
  $values: map-keys($map);

  $i: 0;

  // If the Key Exists, Get the index of the key within the map and add 1 to it for the next breakpoint in the map
  @if (map-has-key($map, $key)) {
    $i: index($values, $key) + 1;
  }

  // If the key doesn't exist, or it's the last key in the map, return null
  @if ($i > length($map) or $i == 0) {
    @return null;
  }
  // Otherwise, return the value
  @else {
    @return map-get($map, nth($values, $i));
  }
}

/// Generates a media query string matching the input value. Refer to the documentation for the `breakpoint()` mixin to see what the possible inputs are.
///
/// @param {Keyword|Number} $val [small] - Breakpoint name, or px, rem, or em value to process.
@function breakpoint($val: $-zf-zero-breakpoint) {
  // Size or keyword
  $bp: nth($val, 1);
  // Value for max-width media queries
  $bp-max: 0;
  // Direction of media query (up, down, or only)
  $dir: if(length($val) > 1, nth($val, 2), up);
  // Eventual output
  $str: '';
  // Is it a named media query?
  $named: false;

  // Orientation media queries have a unique syntax
  @if $bp == 'landscape' or $bp == 'portrait' {
    @return '(orientation: #{$bp})';
  } @else if $bp == 'retina' {
    @return '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
  }

  // Try to pull a named breakpoint out of the $breakpoints map
  @if type-of($bp) == 'string' {
    @if map-has-key($breakpoints, $bp) {
      @if $dir == 'only' or $dir == 'down' {
        $bp-max: -zf-map-next($breakpoints, $bp);
      }

      $bp: map-get($breakpoints, $bp);
      $named: true;
    } @else {
      $bp: 0;
      @warn 'breakpoint(): "#{$val}" is not defined in your $breakpoints setting.';
    }
  }

  // Conditions to skip media query creation
  // - It's a named breakpoint that resolved to "0 down" or "0 up"
  // - It's a numeric breakpoint that resolved to "0 " + anything
  @if $bp > 0em or $dir == 'only' or $dir == 'down' {
    // `only` ranges use the format `(min-width: n) and (max-width: n)`
    @if $dir == 'only' {
      // Only named media queries can have an "only" range
      @if $named == true {
        // Only use "min-width" if the floor is greater than 0
        @if $bp > 0em {
          $str: $str + '(min-width: #{$bp})';

          // Only add "and" to the media query if there's a ceiling
          @if $bp-max != null {
            $str: $str + ' and ';
          }
        }

        // Only use "max-width" if there's a ceiling
        @if $bp-max != null {
          $str: $str + '(max-width: #{$bp-max})';
        }
      } @else {
        @warn 'breakpoint(): Only named media queries can have an `only` range.';
      }
    }

    // `down` ranges use the format `(max-width: n)`
    @else if $dir == 'down' {
      $max: if($named, $bp-max, $bp);

      // Skip media query creation if input value is exactly "0 down",
      // unless the function was called as "small down", in which case it's just "small only"
      @if $named or $bp > 0em {
        @if $max != null {
          $str: $str + '(max-width: #{$max})';
        }
      }
    }

    // `up` ranges use the format `(min-width: n)`
    @else if $bp > 0em {
      $str: $str + '(min-width: #{$bp})';
    }
  }

  @return $str;
}

/// Wraps a media query around the content you put inside the mixin. This mixin accepts a number of values:
///  - If a string is passed, the mixin will look for it in the `$breakpoints` map, and use a media query there.
///  - If a pixel value is passed, it will be converted to an em value using `$global-font-size` as the base.
///  - If a rem value is passed, the unit will be changed to em.
///  - If an em value is passed, the value will be used as-is.
///
/// @param {Keyword|Number} $value - Breakpoint name, or px, rem, or em value to process.
///
/// @output If the breakpoint is "0px and larger", outputs the content as-is. Otherwise, outputs the content wrapped in a media query.
@mixin breakpoint($value) {
  $str: breakpoint($value);
  $bp: index($-breakpoints-keys, $value);
  $pbp: index($-breakpoints-keys, $print-breakpoint);

  // If $str is still an empty string, no media query is needed
  @if $str == '' {
    @content;
  }

  // Otherwise, wrap the content in a media query
  @else {
    // For named breakpoints less than or equal to $print-breakpoint, add print to the media types
    @if $bp != null and $bp <= $pbp {
      @media print, screen and #{$str} {
        @content;
      }
    } @else {
      @media screen and #{$str} {
        @content;
      }
    }
  }
}
