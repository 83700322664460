$white: #ffffff;

// colors are declared as rgb values in order to support opacity adjustments

/** Blue **/
$tw-blue: (
  50: (
    222,
    250,
    255,
  ),
  100: (
    204,
    243,
    255,
  ),
  200: (
    114,
    204,
    255,
  ),
  300: (
    42,
    153,
    255,
  ),
  400: (
    6,
    116,
    245,
  ),
  500: (
    2,
    72,
    208,
  ),
  600: (
    1,
    51,
    164,
  ),
  700: (
    0,
    40,
    150,
  ),
  800: (
    0,
    24,
    120,
  ),
  900: (
    0,
    13,
    103,
  ),
  'accent': (
    0,
    224,
    255,
  ),
);

/** primary **/
$tw-primary: (
  50: (
    245,
    248,
    255,
  ),
  100: (
    233,
    238,
    255,
  ),
  200: (
    186,
    201,
    255,
  ),
  300: (
    131,
    132,
    204,
  ),
  400: (
    71,
    46,
    193,
  ),
  500: (
    29,
    29,
    156,
  ),
  600: (
    0,
    0,
    124,
  ),
  700: (
    0,
    0,
    99,
  ),
  800: (
    0,
    0,
    73,
  ),
  900: (
    0,
    0,
    48,
  ),
  'accent': (
    82,
    0,
    255,
  ),
);

/** Neutral **/
$tw-neutral: (
  50: (
    248,
    252,
    255,
  ),
  100: (
    245,
    248,
    255,
  ),
  200: (
    238,
    241,
    251,
  ),
  300: (
    233,
    238,
    255,
  ),
  400: (
    214,
    223,
    255,
  ),
  500: (
    186,
    201,
    255,
  ),
  600: (
    141,
    141,
    204,
  ),
  700: (
    104,
    104,
    140,
  ),
  800: (
    68,
    68,
    97,
  ),
  900: (
    0,
    0,
    48,
  ),
);

/** Gray **/
$tw-gray: (
  50: (
    250,
    250,
    250,
  ),
  100: (
    240,
    240,
    240,
  ),
  200: (
    230,
    230,
    230,
  ),
  300: (
    224,
    224,
    224,
  ),
  400: (
    189,
    189,
    189,
  ),
  500: (
    136,
    136,
    136,
  ),
  600: (
    102,
    102,
    102,
  ),
  700: (
    68,
    68,
    68,
  ),
  800: (
    51,
    51,
    51,
  ),
  900: (
    43,
    43,
    43,
  ),
);

/** Green **/
$tw-green: (
  50: (
    240,
    255,
    248,
  ),
  100: (
    217,
    255,
    228,
  ),
  200: (
    158,
    236,
    185,
  ),
  300: (
    101,
    209,
    131,
  ),
  400: (
    52,
    183,
    97,
  ),
  500: (
    43,
    120,
    74,
  ),
  600: (
    0,
    86,
    40,
  ),
  700: (
    0,
    73,
    27,
  ),
  800: (
    0,
    60,
    17,
  ),
  900: (
    0,
    45,
    6,
  ),
  'accent': (
    6,
    255,
    172,
  ),
);

/** Orange **/
$tw-orange: (
  50: (
    255,
    238,
    215,
  ),
  100: (
    255,
    229,
    194,
  ),
  200: (
    255,
    211,
    153,
  ),
  300: (
    255,
    193,
    113,
  ),
  400: (
    255,
    176,
    72,
  ),
  500: (
    255,
    158,
    31,
  ),
  600: (
    225,
    127,
    0,
  ),
  700: (
    164,
    93,
    0,
  ),
  800: (
    102,
    58,
    0,
  ),
  900: (
    41,
    23,
    0,
  ),
);

/** Pink **/
$tw-pink: (
  50: (
    250,
    221,
    235,
  ),
  100: (
    248,
    208,
    227,
  ),
  200: (
    243,
    181,
    212,
  ),
  300: (
    239,
    155,
    196,
  ),
  400: (
    235,
    128,
    181,
  ),
  500: (
    231,
    102,
    165,
  ),
  600: (
    223,
    49,
    134,
  ),
  700: (
    182,
    29,
    104,
  ),
  800: (
    129,
    20,
    73,
  ),
  900: (
    76,
    12,
    43,
  ),
);

/** Red **/
$tw-red: (
  50: (
    255,
    248,
    240,
  ),
  100: (
    255,
    229,
    229,
  ),
  200: (
    255,
    192,
    176,
  ),
  300: (
    255,
    128,
    128,
  ),
  400: (
    228,
    59,
    19,
  ),
  500: (
    209,
    51,
    51,
  ),
  600: (
    124,
    0,
    0,
  ),
  700: (
    100,
    0,
    0,
  ),
  800: (
    67,
    0,
    0,
  ),
  900: (
    38,
    0,
    0,
  ),
  'accent': (
    255,
    49,
    0,
  ),
);

/** Yellow **/
$tw-yellow: (
  50: (
    255,
    255,
    222,
  ),
  100: (
    255,
    251,
    214,
  ),
  200: (
    255,
    245,
    152,
  ),
  300: (
    255,
    238,
    78,
  ),
  400: (
    253,
    191,
    33,
  ),
  500: (
    242,
    87,
    0,
  ),
  600: (
    184,
    61,
    0,
  ),
  700: (
    123,
    112,
    10,
  ),
  800: (
    66,
    60,
    5,
  ),
  900: (
    9,
    9,
    1,
  ),
  accent: (
    255,
    245,
    0,
  ),
);

$tw-high-score: (
  light: map-get($tw-green, 100),
  medium: map-get($tw-green, 300),
  dark: map-get($tw-green, 500),
);

$tw-medium-score: (
  light: map-get($tw-yellow, 100),
  medium: map-get($tw-yellow, 300),
  dark: map-get($tw-yellow, 500),
);

$tw-low-score: (
  light: map-get($tw-red, 100),
  medium: map-get($tw-red, 300),
  dark: map-get($tw-red, 500),
);

$tw-no-score: (
  light: map-get($tw-neutral, 100),
  medium: map-get($tw-neutral, 100),
  dark: map-get($tw-primary, 100),
);

$tw-not-attempted: (
  light: map-get($tw-neutral, 100),
  medium: map-get($tw-neutral, 400),
  dark: map-get($tw-neutral, 500),
);

$tw-colors: (
  'blue': $tw-blue,
  'gray': $tw-gray,
  'green': $tw-green,
  'red': $tw-red,
  'orange': $tw-orange,
  'pink': $tw-pink,
  'primary': $tw-primary,
  'neutral': $tw-neutral,
  'yellow': $tw-yellow,
  'high-score': $tw-high-score,
  'medium-score': $tw-medium-score,
  'low-score': $tw-low-score,
  'no-score': $tw-no-score,
  'not-attempted': $tw-not-attempted,
);

// This defines the above Sass color maps as custom properties (css variables):
:root {
  @each $name, $colors in $tw-colors {
    @each $level, $value in $colors {
      --#{$name}-#{$level}: #{$value};
    }
  }
  --white: #ffffff;
}

@function tw-color($base, $value) {
  $color: map-get(map-get($tw-colors, $base), $value);
  @return rgb(nth($color, 1), nth($color, 2), nth($color, 3));
}
