.icon {
  display: inline;
}

.icon svg {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.15rem;
  margin-right: 0.15rem;
  max-width: inherit;
  color: currentColor;
}
.icon-s svg {
  height: 0.8rem;
  vertical-align: -0.05rem;
}

.icon-m svg {
  width: 1.15rem;
  height: 1.15rem;
  vertical-align: -0.15rem;
  margin-right: 0.225rem;
}

.icon-l svg {
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: -0.35rem;
  margin-right: 0.225rem;
}

.icon-xl svg {
  width: 2rem;
  height: 2rem;
  vertical-align: -0.625rem;
  margin-right: 0.325rem;
}

.icon-xxl svg {
  width: 5rem;
  height: 5rem;
}

// Right-aligned icons

.icon.icon--right svg {
  margin-right: 0;
  margin-left: 0.15rem;
}

.icon-l.icon--right svg {
  margin-right: 0;
  margin-left: 0.225rem;
}

.icon-xl.icon--right svg {
  margin-right: 0;
  margin-left: 0.325rem;
}
@-moz-document url-prefix() {
  .fe-edit {
    float: none !important;
  }
}

// Colors
.icon.white svg {
  stroke: var(--white);
}
.icon.text-gray svg {
  stroke: rgb(var(--gray-400));
}
.icon.text-gray-500 svg {
  stroke: rgb(var(--gray-500));
}
.icon.text-gray-700 > svg {
  stroke: rgb(var(--gray-700));
}
.icon.text-green svg {
  stroke: rgb(var(--green-500));
}
.icon.text-green-700 svg {
  stroke: rgb(var(--green-700));
}
.icon.link-color svg {
  stroke: $link-color;
}
.icon svg.grayscale {
  filter: grayscale(100%);
}
