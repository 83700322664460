// -----------------------------------------------------------------------------
// Lesson Plan: default view styles
// -----------------------------------------------------------------------------
#lesson-plan {
  @include breakpoint(large) {
    #assign {
      margin-bottom: 0;
    }
  }
  #assignment-preview > .center {
    display: inline-block;
  }
}

// Assignments banner
#lz-code-banner-feature #go {
  width: 3.8rem;
}

// slideshow navigation

.lesson-plan__lesson {
  padding-bottom: 1rem;
  .lp-deck {
    .lp-deck__footer {
      padding: 1rem 0;
    }
  }
}

.lp-deck__outline {
  list-style: none;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
  max-width: 300px;
  li {
    @apply border-b border-l-4 border-white;
    border-bottom-color: rgb(var(--gray-400));
    padding: $sp-s 0;
    &.meta-hide {
      @apply bg-gray-200;
      a {
        color: rgb(var(--gray-700)) !important;
      }
      &.active {
        border-left-color: rgb(var(--gray-700)) !important;
      }
    }
    a {
      color: $link-color;
    }
  }
  .lp-outline__title {
    font-weight: bold;
    padding-left: 0.2rem;
    a {
      margin-left: -0.75rem;
    }
    text-transform: uppercase;
  }
  .active {
    border-left: 4px solid $link-color;
  }
} // end .lp-deck__outline

// header
.lp-deck__head--with-presenter {
  font-size: $fs-xxs;
  text-align: center;
  a {
    color: $font-color--weak;
  }
}

.lp-deck__head {
  margin-bottom: $sp-s;
  div.lp-deck__slide-title {
    width: 100%;
  }
  .h3 {
    @apply text-primary-600;
  }
  .lp-deck__slide-title {
    @include flexbox();
    @include flexalign(center);
    @include flexdirection(row);
    @include flexwrap(nowrap);
    @apply text-base;
    cursor: pointer;
    font-family: $font-default;
    line-height: 1.4;
    text-align: left;
    width: auto;
    max-width: 100%;

    .card-title {
      @apply inline-block overflow-hidden overflow-ellipsis whitespace-nowrap text-base text-xl font-bold md:text-2xl;
    }

    .icon {
      display: inline-block;
      position: relative;
      margin: $sp-xxs 0 0 $sp-xxs;
      width: 1.5rem;
      &.hide {
        display: none;
      }
    }
  }

  .lp-deck__full-screen,
  .lp-navigation {
    margin-top: 0.1rem;
    float: none;
    @include breakpoint(medium) {
      float: none;
      margin-top: 0.8rem;
    }
  }

  #fullscreen-button-feature {
    display: inline-block;
    .flow-left {
      left: auto;
    }
  }

  .lp-deck__full-screen {
    @media (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2),
      (max-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
      display: none !important; // need to override other classes
    }
    span {
      display: none;
      @include breakpoint(large) {
        display: inline;
      }
    }
    button {
      .icon {
        display: none;
      }
    }
    .icon {
      display: inline;
      padding: 5px;
      margin-right: -2px;
      @include breakpoint(large) {
        margin-right: 0;
        padding: 0;
      }
    }
  }
  .lp-navigation {
    display: inline-block;
    @include breakpoint(large) {
      display: none;
    }
  }

  .margin-top-card-count {
    // equivalent to the height of 'Card x of n' para
    @include flexbox();
    @include flexdirection(row-reverse);
    margin-top: 18px;
    .toggle-btn {
      margin: 0;
      position: relative;
      top: 4px;
      div.toggle-btn__option {
        padding: 0.375rem 0.635rem;
      }
    }
  }
} // end .lp-deck__head

.lp-deck__body {
  > .slideshow__icon--prev,
  > .slideshow__icon--next {
    @include breakpoint(large) {
      display: none;
    }
  }
  .lp-deck__cards {
    position: relative;

    & > li {
      list-style: none;
      opacity: 0;
      position: absolute;
      transform: translate(-300%, -300%);
      transition: opacity 0.4s;
      width: 100%;
      z-index: $z-below;
      display: flex;
      justify-content: center;
      &.active {
        @apply flex flex-col lg:flex-row;
        left: 0;
        opacity: 1;
        position: static;
        transform: none;
        z-index: $z-base;
      }
      &[data-content-type='LearnosityItem'] {
        .card__resource-contents {
          @apply items-start;
          padding-top: $sp-l;
          .btn--pill {
            top: -$sp-l;
          }
        }
      }
    }
  }
} // end .lp-deck__body

.lesson-content {
  .lesson-cards {
    & > li {
      list-style: none;
      opacity: 0;
      position: absolute;
      transform: translate(-300%, -300%);
      transition: opacity 0.4s;
      width: 100%;
      z-index: $z-below;
      &.active {
        left: 0;
        opacity: 1;
        position: static;
        transform: none;
        z-index: $z-base;
      }
      &[data-content-type='LearnosityItem'] {
        .card__resource-contents {
          @apply items-start;
          padding-top: $sp-l;
          .btn--pill {
            top: -$sp-l;
          }
        }
      }
    }
  }
} // end .lp-deck__body

.card {
  @media #{$screen} {
    @include flexbox();
    @include flexalign(stretch);
  }
}

.card__content-wrapper {
  @apply border-base bg-focus relative w-full border-b border-t;
  min-height: 540px;

  &.meta-insert {
    @apply border-primary-accent border-2;
  }
  &.meta-hide {
    @apply border-gray border border-dashed;
  }
  // Make content inside of lesson plan cards inherit the lesson plan's font
  button,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  li,
  p,
  table,
  .btn,
  .detail-item .detail-item__content .detail-item__title,
  .section-accordion h2 > input,
  .card__resource .font-elementary,
  .card__resource .font-secondary {
    font-family: inherit;
  }
  .card__resource {
    @apply min-h-32 md:min-h-64 lg:min-h-80 h-full w-full;
    height: 100%;
    width: 100%;
    @include flexbox();
    @include flexdirection(column);
  }
  .card__resource-contents {
    @apply relative flex h-full w-full items-center justify-center px-4 pb-12;
    min-height: 540px;
    padding: 2%;
    position: relative;
    z-index: $z-base + 1;
    height: 100%;

    > * {
      @apply relative w-full;
      z-index: $z-base + 1;
    }

    .card-content--vcenter {
      @include flexbox-center;
      height: 100%;
      width: 100%;
    }

    #slide-viewer-feature {
      @apply flex;
      height: 100%;
      width: 100%;

      .slide__container {
        padding: 0;
        margin: 0;
        position: initial;
        border: none;
        min-height: unset;
      }
    }

    .image-resource {
      @apply flex flex-col items-center justify-center;
    }

    .page-head {
      .page-head__wrapper,
      .page-head__content {
        padding: 0;
      }
    }
  }

  .card__resource-menu {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-base;
  }

  .btn--pill {
    border-right: none;
    border-top: none;
    border-radius: 0 0.25rem 0 0.5rem;
    font-size: $fs-xxs;
    padding: $sp-xs;
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 6.5rem;
    z-index: 5;
  }

  .card__audio {
    @apply border-base border-t;
    padding: 0.5rem 0;
    text-align: center;
  }
}
// end .card__content-wrapper

// teaching notes
.card__notes {
  max-height: 540px;
  overflow: auto;
  @media #{$screen} {
    @include breakpoint(large) {
      padding: 0;
      position: relative;
    }
    @include breakpoint(small) {
      max-height: auto;
    }
  }
}
.card__notes-wrapper {
  @apply bg-yellow-50;
  border-bottom: 3px solid tw-color('yellow', 400);
  @include breakpoint(large) {
    @apply border border-yellow-400;
    border-radius: $border-radius;
    border-bottom-width: 3px;
    bottom: 0;
    left: 0;
    overflow: auto;
    position: relative;
    right: 0;
    top: 0;
  }
  .card__notes-head {
    @apply border-b border-yellow-400;
    padding: $sp-m;
    @media #{$screen} {
      clear: both;
    }
    span {
      @apply text-base;
      font-size: $fs-m;
      font-weight: $font-weight--bold;
      line-height: $lh-m;
      margin-bottom: 0;
      margin-top: 0;
    }
    a {
      float: right;
      font-size: $fs-s;
      margin-top: 2px;
      .icon {
        margin-right: 0;
      }
    }
  } // end .card__notes-head
  .card__notes-body {
    @apply p-4 break-words;
    line-height: $lh-s;
    margin-top: 0;
    min-height: 200px;
    overflow: auto;
    position: relative;
    p,
    li {
      @apply text-md leading-5;
    }
    p,
    ol,
    ul {
      margin-bottom: $sp-s;
      margin-top: 0;
    }
    ol,
    ul {
      padding-left: $sp-m;
    }
    strong {
      @apply text-black;
    }
    img {
      height: auto !important; // override for ckeditor styles
      display: initial;
    }
    a {
      overflow-wrap: break-word;
    }
    ul,
    ul ul ul ul,
    ul ul ul ul ul ul ul {
      list-style-type: disc;
    }
    ul ul,
    ul ul ul ul ul {
      list-style-type: circle;
    }
    ul ul ul,
    ul ul ul ul ul ul {
      list-style-type: square;
    }
    &:empty:before {
      content: 'There are no teacher notes for this slide';
      font-style: italic;
      font-size: $fs-s;
      line-height: $lh-s;
    }
  } // end .card__notes-body
} // end .card__notes-wrapper
li[data-title-card],
li[data-content-type='None'] {
  h2 {
    margin: 0;
    text-align: center;

    @media (min-width: 600px) {
      font-size: $fs-xxxxl;
    }
  }
}

// content-specific settings
.lp-deck__cards,
.lesson-card-content {
  margin: 0;
  padding: 0;
  li[data-title-card],
  li[data-content-type='Video'],
  li[data-content-type='Hyperlink'],
  li[data-content-type='Image'],
  li[data-content-type='Audio'] {
    .card__resource-contents {
      @include flexbox-center;
    }
  }

  li[data-content-type='Image'] {
    img {
      max-height: 475px;
      max-width: 100%;
    }

    @include breakpoint(large) {
      .download__button {
        display: none; // hide download image button on desktop
      }
    }
  }

  li[data-content-type='Hyperlink'] {
    .detail-item__img {
      display: none;
    }
    .detail-item__content {
      text-align: center;
      @include breakpoint(medium) {
        .detail-item__title {
          font-size: 2rem;
        }
      }
    }
  }

  li[data-content-type='LearnosityItem'] {
    .card__resource-contents {
      @apply items-start;
    }
  }

  .lz-learnosity-item {
    display: block;
  }

  // Learnosity sign up gate
  .card__resource-contents--signup {
    @include flexbox-center;
    min-height: 75vw;
    width: 100%;
    @include breakpoint(large) {
      min-height: 0;
    }
  }
  .document-viewer {
    height: 0;
    padding-bottom: 60%;
    position: relative;

    iframe {
      position: absolute;
    }
  }

  .html-resource {
    // FIXME: Reconcile with font sizing system for slide resource
    @media (min-width: 600px) {
      p,
      li {
        font-size: 1.45rem;
      }
      h2 {
        font-size: 1.75rem;
      }
      h3 {
        font-size: 1.65rem;
      }
      h4 {
        font-size: 1.55rem;
      }
    }
  }

  .geogebra-resource,
  .applet-container {
    display: block;
    height: 100%;
    min-height: 75vw;
    width: 100%;
    @include breakpoint(large) {
      min-height: 0;
    }
  }
  .geogebra-resource {
    border: 1px solid #e6e6e6;
    overflow: hidden;

    // Hide broken and unnecessary View selection button
    .StyleBarPanel_ {
      td:last-child {
        display: none;
      }
      .MyCanvasButton[actioncommand='pointCapture'] {
        margin-right: 5px;
      }
    }
  }

  .audio-player {
    text-align: center;
    width: 100%;
  }

  .card__resource--audio {
    .document-viewer {
      padding-bottom: 55%;
    }
  }

  .w-bottom-bar-middle .w-css-reset-tree div .w-playbar__time {
    width: 30px !important;
  }
}

.question__check-answers {
  @include flexalign(center);
  @include flexbox();
  padding: 1rem 0;
  position: inherit;
  z-index: inherit; // KO component needs this explicitly defined
}

// -----------------------------------------------------------------------------
// Lesson Plan: full screen mode
// -----------------------------------------------------------------------------

body.is_full {
  @apply bg-focus;
  padding: 0;

  &::backdrop {
    @apply bg-focus;
  }

  .page-head,
  .card__notes,
  .lp-deck__footer p,
  .banner,
  #header,
  .notice,
  #header + .bg-orange-100,
  .lp-navigation .small,
  #footer,
  .lesson-plan__description + div,
  .lesson-plan__feedback,
  .collection,
  .collection__content,
  .comments,
  #breadcrumb_nav,
  .pathcrumbs,
  #lesson-plan .lp-deck__body .slideshow__icon--prev,
  #lesson-plan .lp-deck__body .slideshow__icon--next,
  .alert,
  .collection-page__sidebar,
  &.menu-push .collection-page__sidebar,
  .card-actions,
  .lzui-nav-tabs,
  .media-fluid__content .lzui-tile,
  [data-role='admin-bar'],
  .media-fluid__actions {
    display: none !important;
  }

  .page-content {
    border: 0;
    padding: 0;
  }

  .card__resource .page-head {
    display: block;
  }

  #lesson {
    @include flexbox-center;
    display: flex !important; // need to override KO tab binding setting of display: block
    height: 100vh;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;

    .lp-deck {
      @apply bg-focus;
      margin: 0 auto;
      max-width: none;
      transform-origin: top center;
    }

    .lp-deck__head,
    .lp-deck__footer,
    .lp-deck__body,
    .card {
      margin: 0;
      padding: 0;
    }

    .lp-deck__head,
    .lp-deck__footer {
      min-height: 75px;
      padding-top: 0.5rem;
      .lp-navigation {
        padding: 0.5rem 0;
      }
    }

    .card,
    .card__content {
      padding: 0;
      width: 100%;
    }

    .card__content-wrapper {
      @apply border-base border-b border-t;
      border: 0;
      margin: 0 auto;
    }
  }

  li[data-content-type='GeogebraItem'] {
    .card__content-wrapper .card__resource-contents {
      // Fix me -- I think we can kill this...
      overflow: hidden; // remove erroneous scroll bar
    }
  }

  .card__audio {
    bottom: 0;
    height: 42px;
    width: 100%;
    z-index: calc(#{$z-base} + 1);
  }

  .lesson-player {
    @apply max-w-screen-full w-full;
    .card__audio {
      height: 65px;
    }
  }

  .audio-controls {
    display: inline-block;
    position: relative;
  }
}

.card__resource--audio .card__resource-contents {
  height: calc(100% - $ui-height--xlarge);
}

/* fix iOS bug not displaying 100vh and 100vw correctly */
/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .ios body.is_full #lesson {
    height: 700px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ios body.is_full #lesson {
    height: 960px;
  }
}

// Webkit fullscreen fix, so it acts like IE+FF
:-webkit-full-screen {
  top: 0;
  width: 100%;
}

.assignment-progress {
  .assignment-progress-block {
    line-height: 0;
    a.disabled {
      background-color: transparent;
    }
  }
}

@mixin tools-icon-only {
  .vue-dropdown {
    .dropdown-pane.is-open {
      right: 0;
    }
    button {
      margin-top: 0.1rem;
      > span {
        display: none;
      }
      .icon {
        display: none;
      }
      .icon.tool-icon {
        display: block;
      }
    }
    .dropdown-pane {
      width: unset;
    }
    .dropdown-item-help {
      display: none;
    }
    .dropdown-item-link {
      padding-right: $sp-m;
    }
    a {
      font-size: 0;
    }
  }
}

#student-tools-dropdown-feature {
  display: inline;

  > div {
    display: inline;
  }

  .dropdown-pane {
    @include breakpoint(medium up) {
      max-width: 250px;
    }
  }

  @include breakpoint(medium down) {
    @include tools-icon-only;
  }
}

.tool-open {
  .lp-navigation {
    @include breakpoint(large) {
      display: inline-block;
    }
  }
  .lp-deck__head .lp-deck__full-screen span {
    display: none;
  }
  .lp-deck__footer {
    .lp-navigation {
      display: none;
    }
  }
  .card__notes-wrapper {
    position: unset;
  }
}

#student-tool {
  .lzui-modal__close {
    z-index: 2;
  }
}

.is_full {
  #student-tools-dropdown-feature {
    display: none;
  }
  #student-tool {
    display: none;
  }
  #bts_banner {
    display: none;
  }
}

// Some unfortunate importants to override the tailwind reset important overrides
.ck-editable,
.cke_editable {
  ol {
    list-style: decimal !important;
    ol {
      list-style: lower-alpha !important;
      ol {
        list-style: lower-roman !important;
        ol {
          list-style: decimal !important;
          ol {
            list-style: lower-alpha !important;
            ol {
              list-style: lower-roman !important;
              ol {
                list-style: decimal !important;
              }
            }
          }
        }
      }
    }
  }

  ul {
    list-style: disc !important;
    ul {
      list-style: circle !important;
      ul {
        list-style: square !important;
        ul {
          list-style: disc !important;
          ul {
            list-style: circle !important;
            ul {
              list-style: square !important;
              ul {
                list-style: disc !important;
              }
            }
          }
        }
      }
    }
  }

  ol,
  ul {
    margin-top: 1em !important;
    padding: 0 0 0 1.75em !important;
    > li > ol,
    > li > ul {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    li {
      &:not(.lrn-mcq-option) {
        margin: 0 0 0 0.75em !important;
      }
    }
  }
  p {
    margin-top: 1em !important;
    margin-bottom: 0 !important;
  }
  img {
    display: inline-block !important;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    &.lrn-image-center,
    &.lrn-image-left,
    &.lrn-image-right {
      display: block !important;
    }
  }
}

// Unfortunate classes to account for ck-editable / learnosity
.ck-editable {
  .lp-deck__cards,
  .lp-deck__cards > li,
  .lrn_toolbar_left,
  .lrn_toolbar_right,
  .lrn_jxg_toolbox,
  .lrn_jxg_toolbox li,
  .lrn-shading-row,
  .list-metadata {
    margin: 0 !important;
    padding: 0 !important;
  }
  .lrn-shading-row li {
    margin: 0 0 0 -1px !important;
    &:first-child {
      margin-left: 0 !important;
    }
  }
  .detail-list li {
    margin: 1.5em 0 !important;
    &:first-child {
      margin-top: 0 !important;
    }
  }
  .list-metadata li {
    margin-bottom: 2px !important;
  }
}
.lrn_toolbar_left li .lrn_btn .lrn_copy_btn {
  margin: 4px !important;
}

.lrn_toolbar_left,
.lrn_toolbar_right {
  .lrn_btn {
    @apply m-1 #{!important};
    @apply rounded-lg #{!important};
  }
  li {
    list-style: none !important;
  }
}

// Unfortunate learnosity fixes
.lrn_widget,
.ck-editable .lrn_widget {
  &.lrn_graphplotting .lrn_graph_plotter .lrn_toolbar .lrn_btn {
    padding: 0.6em 1em !important;
  }
  .lrn_correctAnswerList {
    margin: 1.8em 0 0 !important;
    padding: 0 !important;
  }
  .lrn_correctAnswerList > li {
    padding: 0.6em 1em 0.6em 2.5em !important;
    margin: 0 0.7em 0.5em 0 !important;
  }
  .lrn-imageupload-annotations,
  .lrn-imageupload-annotations li {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
  }
  .lrn-imageupload-annotation-arrow {
    &:before,
    &:after {
      border-style: hidden;
    }
  }
  .lrn-imageupload-annotation-preview p {
    margin: 0 !important;
  }
  img {
    @apply h-auto max-h-full;
  }
}

// Unfortunate lesson preview fixes
#lesson-plan-preview-feature .ck-editable .lz-learnosity-item {
  ol,
  ul,
  li {
    margin: inherit !important;
    padding: inherit !important;
  }
  .lrn-mcq-option {
    padding: 1px 0 !important;
  }
}

#lesson-plan-preview-feature .ck-editable .lz-learnosity-item,
.ck-editable .lz-learnosity-item,
.lrn-author-item {
  .lrn_stimulus,
  .cke_editable_inline {
    ul {
      margin: 1em 0 0 !important;
      padding: 0 0 0 1.75em !important;
      li {
        margin: 0 0 0 0.75em !important;
        padding: 0 !important;
        list-style: disc !important;
        img {
          display: initial !important;
        }
      }
    }
    ol {
      margin: 1em 0 0 !important;
      padding: 0 0 0 1.75em !important;
      li {
        margin: 0 0 0 0.75em !important;
        padding: 0 !important;
      }
    }
  }
  .lrn_response_input_wrapper {
    ul,
    li {
      margin: 0 !important;
      padding: 0 !important;
    }
    .lrn_texteditor_editable {
      ul,
      ol {
        margin-left: 1em !important;
      }
    }
    .lrn_texteditor .lrn_plaintextarea textarea {
      border: none;
    }
  }
  .lrn-toolbar-wrapper {
    ul li {
      padding: 0.6em 1.2em !important;
    }
  }
}

.cke_editable,
.cke_editable *,
.lrn_response_input_wrapper * {
  outline-offset: 0 !important;
}

.lrn-author-mathgen-chevron {
  display: inline-block !important;
  border-color: currentColor !important;
}

// MathJaX Fix

.math {
  svg {
    display: initial;
    vertical-align: initial;
  }
}

label.print-radio-option:has(input:focus-visible) {
  @apply ring-primary-accent rounded-md ring-2 ring-offset-2;
}
