.slide__fill {
  grid-column: 1 / span 12;
  grid-row: 1 / span 8;
}
.slide__content {
  // initial drag hint
  &.slide__content--blank {
    img,
    svg {
      position: absolute;
      top: 0;
      left: 13%;
      width: 40%;
    }
  }
}
.slide__placeholder {
  @apply border-primary-200 bg-primary-50 border;
  border-radius: $border-radius;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  @include flexbox();
  @include flexalign(center);
  @include flexjustify(center);
  transition: all 0.2s;
  .icon svg {
    max-width: 8rem;
    max-height: 8rem;
    width: 50%;
    height: auto;
  }
}

.slide-editor,
.slide-orderer {
  .slide {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .slide__content {
    @apply border-primary-200 border border-dashed;
    box-sizing: border-box;
    position: relative;
    &.slide__content--selected {
      overflow: auto;
    }
  }
  .slide__content--selected {
    outline: 2px solid tw-color('blue', 500);
    outline-offset: -2px;
  }
  .slide__content--vcenter {
    // ensures overflowed content is still accessible in editing
    .slide__text.cke_focus {
      margin: auto;
    }
    table {
      margin: auto;
    }
  }
  th,
  td {
    .slide__text {
      width: 100%;
    }
  }

  .dialogue {
    min-width: 9rem;
  }
}

// when moving content from one block to another
.slide__content--moving,
#slide-ordered-list .ui-sortable-helper {
  @apply bg-white;
  overflow: hidden;
  opacity: 0.75;
  padding: 0.9375em;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
  transition: transform 0.2s;
  transform: scale(0.8);
  transform-origin: center center;
  font-size: $fs-xl;
  line-height: 1.4;
  z-index: $z-modal;
  p,
  ul,
  ol,
  li {
    font-size: $fs-xl;
    line-height: 1.4;
  }
  .slide__text {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 1) 85%);
    }
  }
}
.slide__content--moving {
  max-height: 10em;
  max-width: 10em;
  height: 184px;
  width: 240px;
}

// displays on top of slide when editing
.slide-toolbar {
  @include flexbox();
  @apply bg-primary border-primary-700 border;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  width: 100%;
}
.slide-toolbar__button,
#preview-toggle {
  font-family: $font-nunito-sans;
  letter-spacing: normal;
}
.slide-toolbar__button {
  @apply text-white;
  border: 1px solid transparent;
  border-radius: $border-radius;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  min-width: 85px;
  text-align: center;
  transition: all 0.2s;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  .icon {
    display: block;
    margin-bottom: $sp-xs;
    opacity: 0.75;
    &:last-child {
      svg {
        width: 10px;
      }
    }
    svg {
      margin: 0;
    }
  }
  label {
    @apply m-0 flex cursor-move items-center justify-center space-x-2 p-4 text-center text-xs font-normal text-white;
    cursor: grab;
  }
  &:hover,
  &:focus,
  &.button-dragging {
    @apply border-primary-600 bg-primary-700 border;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
    .icon {
      opacity: 1;
      &:last-child {
        opacity: 0.8;
      }
    }
  }
  &.button-dragging {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: $z-modal;
    transition: transform 0.2s;
    transform: scale(1.1) rotate(-10deg);
    transform-origin: center center;
    cursor: grabbing;
    label {
      cursor: grabbing;
    }
  }
}

// editing controls for content blocks
#content-menu {
  @apply bg-primary-50;
  position: absolute;
  z-index: $z-dropdown;
  width: auto;
  max-width: none;
  border: 1px solid lighten(tw-color('primary', 400), 5%);
  border-radius: $border-radius;
  box-shadow: 2px 1px 8px rgba(tw-color('primary', 400), 0.5);
  &:after,
  &:before {
    top: 100%;
    left: 2rem;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(250, 250, 250, 0);
    border-top-color: rgb(var(--primary-50));
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(183, 183, 183, 0);
    border-top-color: rgb(var(--primary-500));
    border-width: 11px;
    margin-left: -11px;
  }
  &.content-menu--left {
    right: 0;
    left: unset !important;
    &:after,
    &:before {
      left: auto;
      right: 2rem;
    }
    &:before {
      border-width: 10px;
      margin-left: -10px;
    }
  }
  &.content-menu--center {
    &:after,
    &:before {
      left: 50%;
    }
  }
}
.block-toolbar {
  @include flexbox();
  @include flexalign(center);
  @include breakpoint(medium down) {
    @include flexdirection(column);
  }
  .cke_chrome,
  .cke_inner {
    border: 0;
    background: transparent;
  }
  .cke_top {
    background: white;
    @apply border-primary-200 border;
    border-radius: $border-radius;
  }
  a.cke_button_on {
    @apply bg-primary-200;
    border-radius: 2px;
  }
}
.block-toolbar__button {
  @apply border-primary-200 bg-primary-50 flex flex-col items-center justify-center space-y-2 rounded-none border-r border-none p-4 text-center text-base tracking-normal;
  &:hover,
  &:focus,
  &:active {
    @apply text-base-hover;
  }
  &:active {
    @apply bg-primary-200;
  }
  &:last-child {
    border: 0;
    padding: $sp-s;
  }
  &.slide-content__button--selected {
    @apply bg-primary-200 border;
  }
  .block-toolbar__icon--callout {
    fill: rgba(tw-color('blue', 500), 1);
    @apply rounded bg-blue-100 p-2;
  }
  .block-toolbar__icon--excerpt svg {
    fill: rgba(tw-color('primary', 500), 1);
    @apply bg-neutral-300 rounded p-2;
  }
  .block-toolbar__icon--note svg {
    fill: rgba(tw-color('green', 500), 1);
    @apply rounded bg-green-100 p-2;
  }
  > span {
    @apply flex items-center justify-center space-x-2;
  }
  .icon {
    display: block;
    margin-right: 0;
  }
  .icon-s {
    display: inline-block;
    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  span:last-child {
    font-size: $fs-xs;
  }
}

.vue-dropdown .dropdown-pane.block-toolbar__options {
  @include flexbox();
  max-width: none;
  width: auto;
  .block-toolbar__button {
    padding: $sp-s $sp-m;
  }
  &.block-toolbar__options--long {
    @include flexdirection(column);
    .block-toolbar__button {
      border: 0;
      @apply border-primary-200 flex-col border-b;
      text-align: left;
      padding-top: $sp-m;
      padding-bottom: $sp-m;
      @include flexbox();
      @include flexalign(center);
      &:last-child {
        border: 0;
      }
      span {
        line-height: 1.1;
        margin-bottom: 0;
      }
      > span {
        padding-left: $sp-s;
      }
      strong {
        font-size: $fs-s;
      }
    }
  }
}

// dialogue direction toolbar
.dialogue-toolbar {
  @include flexbox();
  @include flexwrap(wrap);
  max-width: none;
  padding: $sp-s;
  > p {
    width: 100%;
    padding: $sp-s;
    margin-bottom: 0;
  }
  .dialogue-toolbar__direction-selector {
    width: 100%;
    position: relative;

    .dialogue-toolbar__center {
      text-align: center;
      position: absolute;
      @include center-align;
    }
    p.dialogue-toolbar__center {
      @apply text-white;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    .dialogue-toolbar__direction {
      path {
        fill: transparent;
        stroke-dasharray: 10;
        stroke-width: 2px;
        cursor: pointer;
        transition: all 0.15s;
        @apply stroke-primary-600;
      }
      &:hover,
      &:active,
      &.dialogue-toolbar__direction--selected {
        path {
          @apply stroke-primary-600 fill-primary-600;
          stroke-dasharray: none;
        }
      }
    }
  }
  .dialogue-toolbar__direction-selector--thought {
    .dialogue-toolbar__direction {
      path {
        stroke: rgb(var(--orange-800));
        fill: rgb(var(--orange-100));
      }
      &:hover,
      &:active,
      &.dialogue-toolbar__direction--selected {
        path {
          stroke: rgb(var(--orange-800));
          fill: rgb(var(--orange-500));
        }
      }
    }
  }
}

// drag and drop placement indicators
.slide__drop {
  height: 100%;
  width: 100%;
}
.indicator {
  @include flexbox();
  @include flexalign(center);
  @include flexjustify(center);
  z-index: $z-base;
  transition: all 0.2s;
  #indicator-box {
    width: 100%;
    height: 100%;
    background: rgba(tw-color('primary', 50), 0.5);
    @apply border-primary border border-dashed;
  }
}
.drag-overlay {
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 100;
  cursor: move;
}
.resize-gutter {
  z-index: $z-base;
  position: relative;
}
.resize-gutter--bottom {
  height: 14px;
  cursor: row-resize;
  margin: auto 0 auto 0;
}
.resize-gutter--right {
  width: 14px;
  cursor: col-resize;
  margin: 0 auto;
}

// tables
.slide--container__table {
  table.drag__table {
    width: unset;
    border-spacing: 5px;
    border-collapse: unset;
    td {
      width: 20px;
      height: 20px;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.card__resource-form #preview-toggle {
  margin-top: 10px;
  margin-right: 10px;
}
.slide-orderer .slide {
  position: inherit;
  display: block;
  .slide__content {
    .slide__image {
      height: 100px;
      padding: 0;
      position: unset;
      img,
      svg {
        max-height: 100px;
        max-width: 100px;
      }
    }
  }
}
#slide-ordered-list {
  list-style: none;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  > li {
    cursor: move;
    .slide__content {
      .drag-overlay {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .slide__image {
        img,
        svg {
          max-height: 200px;
          position: inherit;
          top: unset;
          left: unset;
          transform: unset;
        }
      }
    }
  }
  .outline-placeholder {
    @apply bg-neutral-50;
    padding-top: 2.4em;
    padding-bottom: 2.4em;
    border-bottom: 1px solid #e6e6e6;
  }
}

// Hide editor on small screens
.slide__editor-unsupported {
  display: none;
  margin: $sp-l auto;
}

@include breakpoint(medium down) {
  .slide__editor-unsupported {
    display: block;
  }

  .slide-editor .slide__container,
  .slide-toolbar,
  #preview-toggle {
    display: none;
  }
}

#alt-dropdown {
  min-width: 200%;
}

// fixes issue w/ svg displaying for a second after closing dropdown
#direction-dropdown {
  &.is-open {
    svg {
      display: block;
    }
  }
  svg {
    display: none;
  }
}
