// -----------------------------------------------------------------------------
// Detail item: List view
// -----------------------------------------------------------------------------

// load more items
.load-more {
  text-align: center;
  padding: 1rem;
  border-top: 0;
  @media print {
    display: none;
  }
}

// -----------------------------------------------------------------------------
// Detail item: Block view
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Resource: Tile View
// -----------------------------------------------------------------------------

// Condensed list view
.condensed-list {
  .selected {
    @apply bg-primary-100;
  }
  td {
    border: 0;
    white-space: nowrap;
  }
  .table__checkbox {
    width: 30px;
  }
  td:last-child {
    width: 100%; // forces the last cell to take up remaining space without causing huge gaps between columns
  }
  @media (max-width: 500px) {
    td:nth-child(1n + 4) {
      display: none;
    }
  }
}

.lzui-tile .condensed-list {
  tr {
    @apply border-b border-gray-200;
  }
  tr:last-child {
    border-bottom-color: transparent;
  }
  td {
    padding: 1rem 14px;
  }
  h4 {
    margin-bottom: 0;
  }
}

// -----------------------------------------------------------------------------
// Resources with unavailable/nonexistent content
// -----------------------------------------------------------------------------

// Styling the faux lines of text
.faux-text {
  @apply bg-gray-200;
  border-radius: 0.125rem;
  height: 0.375rem;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
}

.faux-text--half {
  width: 50%;
}
.faux-text--quarter {
  width: 25%;
}

// Unavailable/non-existent resources

// wrapper div, in which the label/notice is centered
.content-unavailable {
  position: relative;
  // label/notice styling
  .content-unavailable__notice {
    @apply text-orange-800 bg-orange-50 border-orange-200 border;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13rem;
    line-height: 1.4rem;
    font-style: italic;
    font-size: $fs-s !important;
    margin-left: -6.5rem;
    margin-top: -1rem;
    text-align: center;
    padding: $sp-s;
  }
  // override for list view
  .content-unavailable__notice {
    top: 46%;
  }
}

.detail-block {
  width: 100%;
  margin-bottom: 1rem;
  @include breakpoint(medium) {
    width: calc(50% - 0.5rem);
    margin-right: 1rem;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media #{$large-up} {
    width: calc(33.3333% - 1rem);
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    &:nth-child(2n) {
      margin-right: 1.5rem;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
