// --------------------------------
// Assign modal
// --------------------------------

.assign-modal {
  .lzui-modal__dialog {
    @include breakpoint(medium) {
      width: 45rem;
    }
  }

  #copy-lz-code,
  #copy-lti-launch-link,
  #copy-url-launch-link {
    font-family: $font-default;

    @include breakpoint(small down) {
      margin-bottom: $sp-s;
    }

    @include breakpoint(medium) {
      display: inline-block;
    }
  }

  #copy-lz-code {
    @include breakpoint(medium) {
      width: 10rem;
    }
  }

  #copy-lti-launch-link,
  #copy-url-launch-link {
    @include breakpoint(medium) {
      width: 30rem;
    }
  }

  .public-select-label {
    margin-left: $sp-l;

    label {
      display: inline;
    }
  }

  .btn {
    display: inline-block;
    width: auto;
  }
}

#copy-lz-code-button {
  display: none;
}

// --------------------------------
// Assignment page
// --------------------------------

.assignment-progress {
  overflow: hidden;
  padding: 0.75rem 0;
  position: relative;

  .assignment-progress__attempted {
    line-height: 3;
    margin-right: $sp-m;
    text-align: center;

    @include breakpoint(medium up) {
      text-align: left;
    }
  }

  .assignment-progress__attempted,
  .assignment-progress__dots {
    white-space: nowrap;
  }

  .assignment-progress__container {
    @apply border border-gray-200 bg-gray-50;
    border-radius: 0.5rem;
    display: inline-block;
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    .assignment-progress__dots {
      padding: $sp-s;
      overflow-x: auto;
      line-height: 0;
    }

    &:after {
      background: linear-gradient(to left, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
      content: '';
      display: inline-block;
      height: 100%;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
      width: 1.25rem;
    }
  }
}

.assignment-progress__block {
  @include flexbox();
  flex-direction: column;
  overflow: hidden;
  padding: 0 $sp-m;

  @include breakpoint(medium up) {
    flex-direction: row;
  }
}

#assignment .one-column {
  .card__content-wrapper {
    @apply rounded-2xl;
    border-top: 0;
  }

  .lp-deck__head,
  .embed-lp-deck__head {
    @apply bg-base;
    position: sticky;
    z-index: $z-base + 3;
  }

  .lp-deck__head {
    top: -1px;
  }

  .embed-lp-deck__head {
    top: 60px;
  }

  // allows question cards to expand and contract to the question height for better scrolling
  li[data-content-type^='LearnosityItem'] {
    .card__content-wrapper {
      height: auto;
      padding-bottom: 0;
      .card__resource {
        position: inherit;
      }
    }
  }

  .learnosity-preview-card {
    display: none;
  }

  .slideshow__icon--next {
    .icon {
      svg {
        left: 0.1rem;
        position: relative;
      }
    }
  }

  @include breakpoint(large) {
    #lesson-cards {
      .document-viewer {
        padding-bottom: 80%;
      }
    }
  }
}

#lesson {
  li[data-content-type^='LearnosityItem'] {
    .card__content-wrapper {
      .card__resource {
        position: inherit;
      }
    }
  }
}

// Fix me: This is a "fix" for the assignment cards since annotations released
#assignment {
  #assignment-cards:not(.one-column),
  #lesson-cards:not(.one-column) {
    .lp-deck__cards {
      .card__content,
      .card__content-wrapper {
        @apply h-auto w-full transform-none rounded-b-lg #{!important};
      }
    }
    .slide__container {
      aspect-ratio: unset !important;
    }
  }
  #lesson-cards:not(.one-column) .card__content-wrapper {
    padding-bottom: 0 !important;
  }
}

#assignment-cards,
#lesson-cards {
  .question__check-answers {
    padding: 1rem 0.9375rem;
  }
}

#lesson-cards.lg\:col-span-5 {
  .card__content-wrapper {
    padding-bottom: 106.3%;
  }
}

#assignment-preview {
  padding-right: $sp-m;
  width: 100%;

  @include breakpoint(medium) {
    width: auto;
  }

  @include breakpoint(large) {
    padding-right: 0;
  }

  & > .center {
    margin-top: -10px;
  }
}

// --------------------------------
// Fullscreen exceptions
// --------------------------------

body.is_full {
  $assignment-progress-height: 65px;

  #assignment-cards,
  #lesson-cards {
    .lp-deck__head {
      padding: $sp-s;
    }
  }

  #assignment .one-column {
    li[data-content-type^='LearnosityItem'] {
      .card__content-wrapper {
        height: auto;
        padding-bottom: 0;

        .card__resource {
          position: absolute;
        }
      }
    }

    #lesson-cards {
      .card_content-wrapper {
        padding-bottom: 90%;
      }
    }
  }

  #lesson {
    #assignment-cards {
      @apply bg-white;

      &.assessment-deck {
        margin-top: $assignment-progress-height;
      }

      .card__content-wrapper {
        height: 100%;
        width: 100%;

        .card__resource-contents {
          max-height: 80vh;
        }
      }

      .lp-deck__head {
        max-width: none;
      }
      .lp-deck__cards {
        .card__content-wrapper {
          @apply rounded-2xl;
        }
        .lp-card__content {
          height: calc(100vh - 75px);
          width: 100%;

          .card-content--center {
            width: 100%;
          }
        }
      }

      .document-viewer,
      iframe {
        height: 70vh;
      }
    }

    .assignment-progress {
      background: white;
      height: $assignment-progress-height;
      left: 0;
      margin: 0;
      padding: $sp-s 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

// --------------------------------
// Student tools
// --------------------------------

.tool-open {
  .tool-header {
    padding: $fs-xxs 0 $sp-s 0;
  }
}

.student-tool__wrapper {
  @apply bg-focus bg-opacity-80 backdrop-filter backdrop-blur border border-base rounded-lg;
}

.is_full {
  #assignment {
    .student-tools {
      display: none;
    }
  }
}
