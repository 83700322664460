/* Empty states for user generated content */

.empty-state {
  @include flexbox();
  @include flexalign(center);
  @include flexdirection(column);
  @include flexjustify(center);

  margin: 0 auto;
  max-width: 600px;
  padding: $sp-m;
  text-align: center;

  h2 {
    // equates to h3 in size
    font-size: $fs-l;
    margin: $sp-m 0;

    @include breakpoint(large) {
      font-size: $fs-xl;
    }
  }

  p {
    @apply text-muted;
    font-size: $fs-s;
    margin-bottom: $sp-l;
  }
}

.bg-gray-50 .empty-state {
  @apply text-muted;
}

.empty-state__icon {
  margin: 0 auto;
  width: 25%;
}

.empty-state--page {
  min-height: 80vh;
}

.empty-state--component {
  img,
  > svg {
    margin: $sp-l auto 2rem;
    width: 60%;
  }
}

.empty-state--placeholder {
  @include flexalign(center);
  @include flexbox();
  @include flexdirection(column);
  @include flexjustify(center);

  height: 100%;
  margin: 0;
  max-width: none;
  padding: $sp-m;
  width: 100%;

  .icon {
    svg {
      height: auto;
      width: 50%;

      path,
      circle {
        fill: lighten(tw-color('gray', 400), 5%) !important; // override inline styles on svgs
      }

      > path:first-child,
      > circle:first-child {
        display: none;
      }
    }
  }
}

.empty-state--file {
  background: lighten(tw-color('gray', 200), 3%);
  border: 1px dashed lighten(tw-color('gray', 400), 3%);
  border-radius: $border-radius;
}
