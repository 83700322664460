.lzui-nav-tabs,
.lzui-nav-tabs__overflow__tabs {
  @apply flex flex-shrink-0;
  list-style: none;
  clear: both;

  @media print {
    display: none;
  }

  > li,
  > tab-link > li {
    @apply flex justify-center pb-2;

    &:after,
    &active:after {
      @apply absolute bottom-0 left-0 right-0 h-1 w-full transform rounded-full;
      content: ' ';
      transition: transform 0.5s, opacity 0.5s;
    }

    &:after {
      @apply translate-y-1 bg-transparent;
    }

    &.active {
      @apply relative;

      &:after {
        @apply bg-accent translate-y-0 opacity-100;
      }
    }

    a,
    button {
      @apply hover:text-primary hover:bg-accent text-primary-700 outline-none flex cursor-pointer items-center rounded-lg bg-transparent px-2 py-1 font-bold leading-6 transition-colors duration-500 hover:bg-opacity-5;

      @include breakpoint(medium) {
        @apply text-base;
      }
    }

    @include breakpoint(small only) {
      &.collapsible {
        @apply hidden;
      }
    }

    > a,
    button {
      @apply px-4 py-2;
    }
  }

  li {
    &.active {
      a,
      button {
        @apply text-primary-accent hover:bg-transparent;
      }
    }
  }

  .dropdown-pane {
    > li {
      @apply border-l-4 border-transparent;

      &.active {
        @apply border-primary-accent;
      }
    }
  }
}

.tabs-content {
  > .content {
    @apply pt-2;
  }
}

// Vue Tabs!
.lzui-nav-tabs.vue-tabs {
  @apply relative;
}

.lzui-nav-tabs__overflow__tabs {
  @apply hidden;

  @include breakpoint(small only) {
    &.is_open {
      @apply border-neutral-200 z-200 absolute left-0 top-full m-0 flex w-full list-none flex-wrap rounded-none border bg-white p-0 text-left text-base;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);

      li {
        @apply border-base w-full border-b bg-transparent pb-0;
        background: transparent;
        font-size: 0.875rem;

        &:last-child {
          @apply border-b-0;
        }

        a,
        button {
          @apply h-12 w-full rounded-none;
          &.active {
            @apply text-primary-500;
          }
        }
        &:after,
        &active:after {
          @apply absolute bottom-0 left-0 top-0 h-full w-1 transform rounded-none;
          content: ' ';
          transition: transform 0.5s, opacity 0.5s;
        }
      }
    }
  }
}

@include breakpoint(medium) {
  .tabs-content {
    > * {
      @apply pb-4;
      min-height: 600px;
    }

    .content {
      @apply float-none;
    }
  }

  .lzui-modal {
    .tabs-content {
      > * {
        @apply min-h-0; // don't apply min-height when in a modal
      }
    }
  }

  // Vue Tabs!
  li.lzui-nav-tabs__overflow {
    @apply border-0 bg-transparent p-0;

    li:first-child {
      @apply border-l-0;
    }

    a.lzui-nav-tabs__overflow__toggle,
    button.lzui-nav-tabs__overflow__toggle {
      @apply hidden;
    }

    .lzui-nav-tabs__overflow__tabs {
      @apply flex;
    }
  }
}
