#resource {
  .page-head {
    @media print {
      display: none;
    }
  }
}

.resource-info {
  .file-upload__preview {
    width: 100%;
  }
}

input.active-checkbox[type='checkbox'] {
  display: none;

  & + label {
    &:hover,
    label:hover {
      cursor: pointer;
    }

    label:nth-of-type(1) {
      color: #666;
    }

    label:nth-of-type(2) {
      color: #aaa;
    }
  }

  &:checked + label {
    label:nth-of-type(1) {
      color: #aaa;
    }

    label:nth-of-type(2) {
      color: #666;
    }

    .toggle {
      background-color: #4dbc93;
      border-color: #3b9b78;
      left: 2.75rem;
    }
  }
}

#current .icon svg {
  stroke: $link-color;
}

div#wrapper.wistia {
  border-image: url('~images/customdashy.png') 12 12 round;
  border-width: 12px;
  height: 358px;
  margin: 0 auto;
  padding: 0;
}

div.error.wistia {
  color: #aaa;
  font-family: 'Century Gothic', Arial;
  font-size: 14px;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  width: 266px;
}

#resource-content {
  .document-viewer {
    height: 700px;
  }
}

#standalone-video {
  .video-player {
    width: 800px;
    min-height: 500px;
  }
}
.video-player {
  position: relative;
}

// Styles for link resource iframe
.link-iframe--wrapper {
  height: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative; //This is for 16x9 aspect ratio
  width: 100%;

  iframe {
    @apply border border-gray-200;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@include breakpoint(medium down) {
  .link-iframe--wrapper {
    //fix to iframe getting height of 0 on FF
    min-height: 54vw;
  }
}

// Styles for audio resource
.audio-controls {
  .icon {
    svg {
      fill: rgb(var(--gray-700));
    }
  }

  .volume-dropdown {
    height: 110px;
    width: 25px;
    left: 25%;

    input[type='range'] {
      margin: 0;
      padding: 0;
      transform: translate(-38px, 48px) rotate(270deg);
      width: 100px;
    }

    @media print {
      display: none;
    }
  }

  .audio-seekbar {
    @apply bg-gray-200;
    display: inline-block;
    height: 0.5rem;
    margin-bottom: 0.08rem;
    position: relative;
    transition: color 0.25s ease, background-color 0.25s ease, opacity 0.5s ease;
    width: 45%;
    z-index: $z-base;
    @include breakpoint(medium) {
      width: 17rem;
    }

    @media print {
      width: 60%;
    }

    .audio-seekbar__progress {
      @apply bg-primary-600;
      height: 100%;
      position: relative;
      z-index: $z-base + 1;
    }
  }
}

.resource-embedded {
  clear: left;
  margin: $sp-m 0;
  max-width: 640px;
  width: 100%;

  .audio-controls {
    @apply border border-gray-200;
    border-radius: 5px;
    padding: 1rem;
    text-align: center;
    width: 100%;
  }

  .document-viewer {
    height: 700px;
  }
}

.edit_wiki,
.new_wiki {
  .page-head__cover-image {
    label,
    .help-block,
    h2,
    input[type='text'],
    textarea,
    p,
    a,
    .btn--demoted {
      @apply text-white;
    }

    input[type='text'],
    textarea {
      background: rgba(0, 0, 0, 0.4);
    }

    cover-image-upload,
    .cover-image-upload {
      img,
      .cover-image__no-image {
        display: none;
      }
    }
  }
}

// Rich text / html resources
.html-resource {
  ul,
  ol {
    overflow: hidden;
  }
}

// Geogebra resources
.geogebra-resource {
  @apply border border-gray-200;
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .applet-thumbnail {
    max-height: 100%;
    max-width: 100%;

    &.default-thumbnail {
      bottom: 0;
      height: auto;
      left: 0;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
  }
}

// wikipage resource modal
.lz-content-modal__choose {
  .hyperlink__preview,
  .hyperlink__layout {
    display: none;
  }
}
