// Default (enclosed)

table {
  width: 100%;
  @apply max-w-full;
  border-spacing: 0;

  tr {
    @apply bg-focus;
  }

  th,
  td {
    @apply border-primary-100  border-0.5 text-base;
    padding: 8px;
    vertical-align: top;
    &.selected {
      @apply bg-inverted text-inverted;
    }
  }
  th,
  .th {
    @apply bg-neutral-100 text-important;
    text-align: left;
  }

  tbody:first-child > tr:first-child,
  thead:first-child > tr:first-child {
    td,
    th {
      @apply border-t;
    }
    > td:first-child,
    > th:first-child {
      @apply rounded-tl-2xl border-l;
    }
    > td:last-child,
    > th:last-child {
      @apply rounded-tr-2xl border-r;
    }
  }
}

.big_modal_table {
  @include breakpoint(medium) {
    max-height: 65vh;
    overflow-y: auto;
  }
}

.modal_table {
  max-height: 50vh;
  overflow-y: auto;
}

.small-modal_table {
  max-height: 35vh;
  overflow-y: auto;
}

tr.clickable {
  &:hover {
    @apply bg-base;
  }
  &:active {
    @apply bg-base;
  }
}

table.rounded-0 {
  tbody:first-child > tr:first-child,
  thead:first-child > tr:first-child {
    td,
    th {
      border-radius: 0;
    }
  }
  tbody:last-child > tr:last-child,
  thead:last-child > tr:last-child {
    td,
    th {
      border-radius: 0;
    }
  }
}

// Open (horizontal lines)
table.table-opened {
  border: none;
  th,
  td {
    border-left: none;
    border-right: none;
    font-size: $fs-s;
    padding: $sp-s;
    text-align: left;
    vertical-align: middle;
  }
  thead th {
    vertical-align: bottom;
    text-transform: uppercase;
    font-size: $fs-xxs;
    letter-spacing: 1px;
    padding: 6px 8px 4px;
  }
  &.table-opened--bordered {
    @apply border-primary-100 border-l border-r;
    td {
      @apply bg-white;
    }
    tr:nth-child(even) {
      @apply bg-primary-50 #{!important};
      border: none !important;
    }
  }
  tbody:first-child > tr:first-child,
  thead:first-child > tr:first-child {
    td,
    th {
      border-radius: 0;
    }
  }
  tbody:last-child > tr:last-child,
  thead:last-child > tr:last-child {
    td,
    th {
      border-radius: 0;
    }
  }
}

.table--dynamic {
  display: inline-block;
  overflow-x: scroll;
  td {
    vertical-align: middle;
    height: 53px;
  }
  td:first-child {
    width: 1rem;
  }
  @include breakpoint(large) {
    display: table;
  }
}

// Misc modifiers

td.middle {
  vertical-align: middle;
}

table.wiki-standards {
  margin-bottom: 3rem;
  thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  thead th,
  thead th h4 {
    font-weight: bold;
    text-transform: none;
    margin-bottom: 0;
  }
  tbody td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  tbody tr td:first-child {
    white-space: nowrap;
    padding-right: 2.5rem;
  }
}

/* responsive styling */
table.responsive {
  display: inline-block;
  max-width: 100%;
  overflow-x: auto;
  padding-right: 1px;
  border: 0;
  width: auto;
}

.overflow-indicator-left,
.overflow-indicator-right {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  background-size: 10px 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
}
.overflow-indicator-left {
  background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center;
}
.overflow-indicator-right {
  background: radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
}
