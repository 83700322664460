// -----------------------------------------------------------------------------
// Universal page structure
// FIXLATER: Some 3+ layers of nesting in here for design polish hours
// -----------------------------------------------------------------------------
body.main {
  @include flexbox();
  @include flexdirection(column);
  min-height: 100vh;

  @media print {
    display: block;
  }
}

footer {
  margin: auto 0 0 0;
}

.cover-image-form {
  .help-block {
    @apply text-white;
  }
  label {
    @apply font-bold text-white shadow-md;
    span {
      @apply text-white shadow-md;
    }
  }
}

.page-head {
  position: relative;
  width: 100%;

  .page-head__wrapper {
    @apply px-3 md:px-6;
    @include flexbox();
    margin: 0 auto;
    max-width: 80rem;
    padding-bottom: 2rem;
    padding-top: 2rem;

    @include breakpoint(medium down) {
      @include flexwrap(wrap);
    }

    @media print {
      margin-top: 0;
    }

    .icon-xxl {
      margin-right: 1rem;

      @include breakpoint(medium) {
        margin-top: $sp-l;
      }
    }
  }

  &.page-head--wide {
    .page-head__wrapper {
      @apply max-w-screen-2xl;
    }
  }
}

.page-head__content {
  @include flex-val(auto);

  @include breakpoint(medium down) {
    @include flex-val(85% 0 0);
    padding-bottom: 2rem;
  }

  @media print {
    padding-bottom: 0;
  }

  h1,
  h2 {
    font-size: $fs-xxl;
    margin-bottom: 0.75rem;
    margin-top: 0;

    @media print {
      font-size: $fs-xl;
    }
  }

  .page-head__pretext {
    @apply text-primary;

    @media print {
      display: none;
    }
  }

  .page-head__metadata {
    margin-bottom: 0.5rem;
    max-width: 1000px;

    .page-head__logo {

      img {
        max-height: 1rem;
        max-width: 1rem;
        vertical-align: baseline;
      }

      @include breakpoint(medium) {
        display: none;
      }
    }

    .lz-standards > .common-core-tip {
      @apply text-muted border-base;
      display: inline-block;

      &.build-code {
        @apply border-base text-muted;

        &:hover {
          @apply border-base-hover text-important;
        }
      }
      &:hover {
        @apply border-base-hover text-important;
      }
    }
  }

  .page-head__tags {
    strong {
      float: left;
    }

    ul {
      margin: 0;
      overflow: auto;
      padding: 0 0 0 0.5rem;
    }

    li {
      float: left;
      margin: 0;

      &:not(:last-child):after {
        content: ', ';
        margin: 0 3px 0 -1px;
      }

      &:last-child:after {
        content: '';
      }

      a {
        border-radius: $border-radius;
      }
    }
  }
}

.page-head__image {
  @include flex-val(15% 0 0);
  display: none;
  max-width: 6rem;

  @include breakpoint(medium) {
    display: block;
  }

  @include breakpoint(large) {
    @include flex-val(9% 0 0);
  }
}

.page-head__actions {
  @include flex-val(100% 0 0);

  .btn--small {
    min-width: 115px;
  }

  @include breakpoint(large) {
    @include flex-val(auto 0 0);

    .btn,
    .page-head__button {
      display: inline-flex;
    }

    .page-head__button {
      .lzui-modal {
        white-space: normal;
      }
    }
  }

  @media print {
    display: none;
  }

  .lzui-modal {
    .btn {
      display: inline-block;
      width: auto;
    }
  }

  .lzui-modal {
    .load-more .btn {
      width: 100%;
    }
  }
}

.page-content {
  @apply border-base border-t;
  min-height: 300px;
  padding: 1rem 0 $sp-l;
  z-index: $z-base;

  @media print {
    padding-top: 0;
    border: 0;
  }
}

@include breakpoint(large) {
  .lzui-min-height {
    min-height: 600px;
  }
}

.is_full {
  .page-content > *:first-child {
    padding-top: 0;
  }
}

/* customer logo preview */
@include breakpoint(large) {
  .image-preview {
    @include flexbox();

    .page-head__image {
      @include flex-val(20% 0 0);
    }

    .image-preview__large {
      @include flex-val(38% 0 0);
    }

    .image-preview__medium {
      @include flex-val(33% 0 0);
      margin-right: 3%;
    }

    .image-preview__small {
      @include flex-val(25% 0 0);
    }
  }
}
