// -----------------------------------------------------------------------------
// No bullets / plain
// -----------------------------------------------------------------------------

.no-bullet {
  list-style: none;
  padding-left: 0;
  li {
    list-style: none;
  }
}

// -----------------------------------------------------------------------------
// Inline-Block List
// -----------------------------------------------------------------------------

.list-inline-block {
  list-style: none;
  padding-left: 0;
  li {
    display: inline-block;
  }
}
.list-inline-block.list-left {
  clear: both;
  li {
    float: left;
  }
}
.list-inline-block.list-right {
  clear: both;
  li {
    float: right;
  }
}
.list-inline-block.list-center {
  text-align: center;
}

// -----------------------------------------------------------------------------
// Misc.
// -----------------------------------------------------------------------------

// Nested Lists
ul ul,
ol ol,
ul ol,
ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

ol,
ol ol ol ol,
ol ol ol ol ol ol ol {
  list-style-type: decimal;
}
ol ol,
ol ol ol ol ol {
  list-style-type: lower-alpha;
}
ol ol ol,
ol ol ol ol ol ol {
  list-style-type: lower-roman;
}

ul,
ul ul ul ul,
ul ul ul ul ul ul ul {
  list-style-type: disc;
}
ul ul,
ul ul ul ul ul {
  list-style-type: circle;
}
ul ul ul,
ul ul ul ul ul ul {
  list-style-type: square;
}

// -----------------------------------------------------------------------------
// Definition Lists
// -----------------------------------------------------------------------------

dl {
  dt,
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  dt {
    font-weight: bold;
    margin-top: 1rem;
  }
  dt:first-child {
    margin-top: 0;
  }
  dd {
    // inherits default copy sizing
  }
}

// -----------------------------------------------------------------------------
// List of rows
// -----------------------------------------------------------------------------

.list-rows {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  & > li {
    list-style: none;
    border-bottom: 1px solid $border-color;
  }
  & > li:last-child {
    border-bottom: none;
  }
}

// -----------------------------------------------------------------------------
// Metadata list
// -----------------------------------------------------------------------------

.list-metadata {
  padding-left: 0;
  li {
    @include breakpoint(medium down) {
      font-size: $fs-xxs;
    }
    list-style: none;
    margin-bottom: 2px;
    font-size: $fs-xs;
    line-height: 1.45;
    strong {
      @apply text-primary-800;
    }
  }
}

.page-head__metadata.list-metadata {
  li {
    font-size: $fs-s;
    margin-bottom: 0.5em;
  }
}

// -----------------------------------------------------------------------------
// Selection list
// Applies styling to the currently selected list item
// -----------------------------------------------------------------------------

.active-selection {
  position: relative;

  .active-indicator {
    @apply bg-accent absolute left-0 top-0 h-full w-1 rounded bg-transparent;
  }
}
