// --------------------------------
// Animation utility classes
// --------------------------------

@keyframes scale-up {
  0% {
    transform: scale(0);
  }

  90% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.animation--scale-up {
  animation: scale-up 1s forwards ease-in;
  transform: scale(0);
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes slide-in-sm {
  0% {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.animation--slide-in {
  animation: slide-in 0.5s 0.1s forwards ease;
  opacity: 0;
}

.animation--slide-in-sm {
  animation: slide-in-sm 0.33s 0.075s forwards ease;
  opacity: 0;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s forwards ease-in;
}

@keyframes pop {
  0% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.animation--pop {
  animation: pop 0.5s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animation--fade-in {
  animation: fade 0.5s forwards ease-in;
}

@keyframes ring {
  0% {
    transform: rotate(-15deg);
  }

  10% {
    transform: rotate(13deg);
  }

  20% {
    transform: rotate(-11deg);
  }

  30% {
    transform: rotate(9deg);
  }

  40% {
    transform: rotate(-7deg);
  }

  50% {
    transform: rotate(5deg);
  }

  60% {
    transform: rotate(-3deg);
  }

  70% {
    transform: rotate(2deg);
  }

  80% {
    transform: rotate(-1deg);
  }

  90% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.animation--ring {
  animation: ring 1s cubic-bezier(0, 0, 1, 0.53) forwards;
}

@-webkit-keyframes floating {
  from {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 7px);
  }

  to {
    transform: translate(0, 0);
  }
}

.animation--float {
  animation: floating 1s ease-in-out 3;
}
