// -----------------------------------------------------------------------------
// Lesson Plan: Editing mode
// -----------------------------------------------------------------------------

.card-editor {
  .filestack-accessor {
    text-align: center;
  }
}

.edit-mode {
  .lp-deck__head #full-screen-toggle {
    margin-bottom: $sp-s;

    fullscreen-button {
      float: right;
      margin-left: $sp-xs;
    }

    @include breakpoint(small down) {
      .margin-top-card-count {
        margin-top: 0;
      }
      .card-actions {
        width: 100%;
        padding-top: $sp-s;
      }
    }
  }
  .lp-deck__full-screen {
    padding: 0.375rem 0.625rem;
    font-size: $fs-s;
    margin-top: 18px;
    span {
      display: none;
    }
    svg {
      margin-right: 0;
    }
  }

  .lesson-plan__additional-material {
    .lzui-tile > div {
      border-bottom: 1px solid #ddd;
      &:last-child {
        border: 0;
      }
    }
  }
}

.reorder-modal {
  ol {
    max-width: 100%;
  }
  li {
    &.handle {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
    }
    @apply border border-gray-200 bg-gray-50;
    border-radius: $border-radius;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.5rem;
    padding: $sp-s $sp-s $sp-s $sp-xs;
    transition: all 0.2s;
    @include flexbox();
    @include flexalign(center);
    transition: all 0.2s;
    &:first-child {
      @apply border-t border-gray-200;
    }
    .icon {
      padding: $sp-xs $sp-s $sp-xs 0;
      svg {
        transform: rotate(90deg);
        stroke: rgb(var(--gray-400));
      }
    }
    &:hover,
    &:focus,
    &.ui-sortable-helper {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
    }
    &.ui-sortable-helper {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: $z-modal;
      transition: transform 0.2s;
      transform: scale(1.02) rotate(-3deg);
      transform-origin: center center;
      cursor: grabbing;
    }
  }
  .lp-outline__title {
    padding-left: 0;
  }
  .lp-deck__outline {
    max-height: none;
    .outline-placeholder {
      @apply bg-green-100;
      padding: 1rem 0.3rem;
      border-bottom: 1px solid #e6e6e6;
    }
  }
}

.lp-editor__blank-slate {
  .help-block {
    font-size: $fs-m;
  }
}

// card editor
.card-editor {
  text-align: left;
  .card__resource-contents {
    height: auto;
  }
  .card-editor__save {
    @apply border-t border-gray-200;
    padding: $sp-m 0 0;
  }
  .card-editor__assignment {
    .help-block {
      margin: 0 $sp-m;
    }
  }
  .card__notes-wrapper {
    @apply h-full;
    @include flexbox();
    @include flexdirection(column);

    ul {
      list-style-type: disc;
    }
  }
  .card__notes-body {
    padding: 0;
    @include breakpoint(large) {
      flex: 1 1 0;
    }
  }

  // no content
  .tabs-content {
    @apply border-gray-200;
    border-top-left-radius: 0;
    > * {
      min-height: 0;
    }
  }
  .card-editor__content-types {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    @include flexbox();
    @include flexwrap(wrap);
    @include breakpoint(large) {
      margin-top: 1rem;
    }
    li {
      margin: $sp-m 3%;
      width: calc((100% / 3) - 6%);
      @include breakpoint(medium) {
        margin: $sp-l 2%;
        width: calc((100% / 5) - 4%);
      }
      @include breakpoint(large) {
        width: calc((100% / 6) - 4%);
      }
    }
  }

  // resource editors
  .card-editor__remove-content,
  .card-editor__remove-notes {
    position: absolute;
    z-index: $z-modal + 1;
    right: 0;
    top: 0;
    .icon {
      @apply bg-primary-accent border border-gray-200 text-white;
      height: 2rem;
      width: 2rem;
      display: block;
      border-radius: 50%;
      z-index: $z-modal + 1;
      position: absolute;
      top: -1rem;
      left: -1.5rem;
      transition: all 0.3s;
      transform-origin: center;
      svg {
        position: absolute;
        top: 0.45rem;
        left: 0.45rem;
        margin: 0;
        vertical-align: middle;
      }
    }
    //*** FIXME: Styling for the change content button
    span {
      width: 0;
      transition: all 0.3s;
      opacity: 0;
      white-space: nowrap;
      overflow: hidden;
    }
    &:hover,
    &:active {
      span {
        width: auto;
        transition: all 0.3s;
        padding: 0 0.5rem;
        opacity: 1;
      }
    }
  }
  .card-editor--hide,
  .page-head,
  .file-upload__preview,
  .download__button {
    display: none;
  }
  .card__replace-content {
    .rich-textarea {
      height: 100%;
    }
  }
  .card__content-wrapper {
    @apply relative;
  }
  .card__content-wrapper[data-type^='title'] {
    .card__resource-contents {
      @include flexbox-center;
      h2 {
        text-align: center;
        font-size: 2.4rem;
      }
    }
  }
  .card__content-wrapper[data-type^='video'],
  .card__content-wrapper[data-type^='document'],
  .card__content-wrapper[data-type^='image'],
  .card__content-wrapper[data-type^='hyperlink'],
  .card__content-wrapper[data-type^='slide'] {
    height: auto;
    padding-bottom: 0;
    .card__resource {
      position: relative;
    }
    .card__resource-form {
      @include flexbox();
      @include flexdirection(column);
    }
    .hyperlink__preview {
      @include order(-1);
      // Firefox has a bug with flexbox and the aspect ratio trick so need this extra wrapper
      .hyperlink__preview-wrapper {
        position: relative;
        padding-bottom: 80%;
        height: 0;
        @apply border-b border-gray-200;
      }
      .detail-item__title {
        font-size: 2rem;
      }
    }
  }

  .card__content-wrapper[data-type^='document'],
  .card__content-wrapper[data-type^='image'] {
    .hyperlink__preview {
      .hyperlink__preview-wrapper {
        padding-bottom: 60%;
        .document-viewer {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .card__content-wrapper[data-type^='html'],
  .card__content-wrapper[data-type^='geogebra'],
  .card__content-wrapper[data-type^='wiki'],
  .card__content-wrapper[data-type^='learnosity'] {
    height: auto;
    padding-bottom: 0;

    .card__resource {
      position: relative;
    }
  }

  .card__content-wrapper[data-type^='learnosity'] {
    .card__resource-contents {
      min-height: $sp-xxxl * 2;
      overflow: auto !important;
      align-items: flex-start;
    }
  }

  .card__content-wrapper[data-type^='html'] {
    .card__resource-editor,
    .card__resource-container,
    .card__resource-form {
      height: 100%;
    }
    .card__resource-form {
      @include flexbox();
      @include flexdirection(column);
    }
  }
  .card__content-wrapper[data-type^='hyperlink'],
  .card__content-wrapper[data-type^='wiki'] {
    .page-head {
      border-bottom: 0;
      display: block;
      margin-bottom: 0 !important; // override helper class
    }

    .resource__title {
      left: 0;
      width: 100%;
    }
  }
  .card__content-wrapper[data-type^='slide'] {
    .card__resource-contents {
      padding: 0;
    }
    .col-span-8.form-group {
      width: 100%;
      padding: 0;
    }
    .slide__container {
      @apply w-full;
    }
  }
  .card-editor--center {
    text-align: center;
  }

  // set content previews to 4:3 aspect ratio
  .card__replace-content--preview {
    .card__content-wrapper {
      height: auto;
      padding-bottom: 0;
      min-height: 75%;
      .card__resource {
        position: relative;
      }
    }
    .card-editor__preview-content-wrapper {
      @apply border-b border-gray-200;
      position: relative;
      width: 100%;
      margin-bottom: $sp-m;

      .card-editor__preview-content {
        height: 100%;
        width: 100%;
        position: absolute;
        @include flexbox-center;

        img {
          max-height: 90%;
          max-width: 100%;
        }

        .document-viewer {
          width: 100%;
          height: 100%;

          iframe {
            border: 0;
          }
        }
      }
    }
  }
  .file-upload {
    @include flexjustify(center);
    .file-upload__buttons {
      @include flexjustify(center);
    }
    .file-upload__download {
      max-width: 100%;
    }
  }

  // Voiceover
  .card__audio {
    font-family: $font-default;
    letter-spacing: normal;
    padding-top: 0;

    &-uploader {
      @include flexbox-center;
    }

    .file-upload {
      display: inline-block;

      &.has-inline-picker {
        display: block;
        width: 100%;
      }
    }
    input[type='text'] {
      margin-bottom: $sp-s;
    }
    @include breakpoint(large) {
      label {
        padding-top: $sp-s;
        display: inline;
      }
      input[type='text'] {
        display: inline;
        margin: 0 0 0 $sp-s;
        width: auto;
      }
      .ko-component,
      .audio-controls {
        display: inline;
      }
    }
  }

  /* Editor UI should use Open Sans */
  .card__content-wrapper.tabs-content {
    .btn,
    .card__resource-contents,
    b,
    em,
    i,
    strong {
      font-family: $font-nunito-sans;
      letter-spacing: normal;
    }
  }

  .page-head {
    font-family: $font-nunito-sans;
    letter-spacing: normal;
  }

  /* But inputs for content to be displayed to students should use Report where appropriate */
  .font-elementary input {
    @include font-report;
  }

  .card__content-wrapper[data-type^='audio'],
  .card__content-wrapper[data-type^='document'],
  .card__content-wrapper[data-type^='geogebra'],
  .card__content-wrapper[data-type^='hyperlink'],
  .card__content-wrapper[data-type^='image'],
  .card__content-wrapper[data-type^='video'] {
    .card__resource-form {
      font-family: $font-nunito-sans;
      letter-spacing: normal;

      .btn,
      b,
      em,
      i,
      strong {
        font-family: $font-nunito-sans;
        letter-spacing: normal;
      }
    }
  }

  .hyperlink__preview {
    @include font-report;
  }
} // end .card-editor

// additional materials (eventually this will be updated to not use the same card editor)
.card__content--additional-materials {
  .card__content-wrapper {
    height: auto;
    padding-bottom: 0;

    .card__resource {
      position: relative;
    }
    .card__resource-form,
    .card__resource-contents,
    .page-head {
      font-family: $font-nunito-sans;
      letter-spacing: normal;
    }
    .hyperlink__preview,
    .hyperlink__layout {
      display: none;
    }
  }
  .card-editor__preview-content-wrapper {
    display: none;
  }
  .card__content-wrapper[data-type^='title'] {
    .card__resource-contents {
      display: block;
      h2 {
        @apply text-gray-700;
        font-family: $font-default;
        font-weight: bold;
        text-align: left;
        font-size: 1.53rem;
      }
    }
  }
  .card__content-wrapper[data-type^='hyperlink'] {
    .hyperlink__preview {
      padding: 0;
      @include order(0);
    }
  }
  .card__content-wrapper[data-type^='hyperlink'],
  .card__content-wrapper[data-type^='wiki'] {
    .page-head {
      display: none;
    }
  }
} // end .card__content--additional-materials
