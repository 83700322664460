.dropdown-pane {
  @apply transition-spacing invisible mt-0 h-0 list-none overflow-hidden rounded-lg text-left shadow duration-100;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  z-index: $z-dropdown; // so it will display over any overlay

  > li:first-child {
    @apply rounded-t-lg;
  }

  > li:last-child {
    @apply rounded-b-lg;
  }

  &.is-open {
    @apply visible mt-2 h-auto overflow-y-auto;
  }

  @include breakpoint(medium) {
    max-width: 200px;
    &#page-actions {
      max-width: 250px;
    }
  }

  > li {
    list-style: none;
    font-size: $fs-s;

    a,
    button {
      @apply p-2.5 text-base;
      cursor: pointer;
      display: block;
      text-align: left;
      width: 100%;
    }

    a,
    > span {
      @apply text-base;
      cursor: pointer;
      display: block;
    }

    a > span,
    button > span {
      &.node-wrapper {
        @apply text-base;
      }
      @apply text-accent;
    }

    &:hover span.icon {
      @apply text-accent;
    }

    span.icon {
      @apply text-accent;
    }

    a.disabled,
    > span {
      color: lighten(tw-color('gray', 400), 10%);
    }

    &[disabled] > span {
      color: lighten(tw-color('gray', 400), 10%);
    }

    &:hover {
      @apply bg-base;
    }

    a.red {
      color: #f15a29;
    }

    &.disabled,
    .disabled {
      @apply text-gray;
      cursor: default;
    }

    .smaller {
      font-size: $fs-xxs;
    }

    button {
      line-height: $lh-l;
    }
  }

  &.dropdown-links {
    // for dropdowns made up of a list of links
    strong {
      display: block;
    }
  }

  &.dropdown-pane--info {
    padding: 1.5rem 1.5rem 1rem 1rem;
  }

  .dropdown-pane__close {
    height: 1rem;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    width: 1rem;
  }
}

.vue-dropdown {
  position: relative;
  display: inline-block;
  button {
    margin-bottom: 0;
  }
  .dropdown-pane {
    position: absolute;
    left: auto;
    top: auto;
    width: 300px;
    z-index: $z-dropdown;
    background: white;
    right: 0; // prevents unwanted horizontal scroll bar
    &.is-open {
      right: auto;
    }
    // Don't stomp on tailwind
    &.is-open.right-0 {
      right: 0;
    }
    &.md\:flow-center {
      @include breakpoint(medium) {
        left: 50%;
        transform: translateX(-50%);
        &.flow-left {
          left: unset;
          transform: unset;
        }
      }
    }
    &.flow-left {
      right: 0;
    }
    &.top {
      bottom: 102%;
    }
    &.left {
      @include breakpoint(medium up) {
        top: 0;
        right: 102%;
      }
    }
    &.right {
      @include breakpoint(medium up) {
        top: 0;
        left: 98%;
      }
    }
    > li {
      position: relative;
      border-left: 4px solid transparent;

      &.active {
        border-left-color: rgb(var(--primary-accent));
        a {
          @apply text-primary-accent;
        }
      }

      .dropdown-item-link {
        @include breakpoint(medium up) {
          padding-right: $sp-xl;
        }
      }

      .dropdown-item-help {
        position: absolute;
        display: none;
        width: 1rem;
        height: 1rem;
        padding: 0;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);

        @include breakpoint(medium up) {
          display: block;
        }
      }
    }
  }
}

.dropdown-pane.vue-dropdown {
  display: block;
}

.align-right .vue-dropdown {
  .dropdown-pane {
    left: 0; // prevents horizontal scroll bar
    &.is-open {
      left: 0;
      &.flow-left {
        left: auto;
      }
    }
  }
}

.v-select {
  .vs__dropdown-menu {
    li {
      padding: 0.5ex 0.5em;
      white-space: normal;
      &.active,
      &.vs__dropdown-option--selected {
        @apply bg-green-100 text-base;
        .result-display-code:before {
          content: '✓ ';
        }
        &:hover {
          background: darken(tw-color('green', 100), 20%);
        }
      }
    }
  }
}
