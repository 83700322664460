// --------------------------------
// FIXLATER: 3+ levels of nesting are due to not following BEM class naming all the way down.
// --------------------------------

.no-scroll {
  overflow: hidden;
}

.blurred:not(.cke_reset_all):not(.loading-spinner__overlay):not(.tooltip) {
  filter: blur(3px);

  transition: filter 0.2s;
}

.lzui-modal__close,
.lzui-modal__help {
  @apply text-muted border-base bg-focus absolute right-1 top-1 rounded-full border-2 p-1.5 transition-all;

  svg {
    display: block;
    height: 1rem;
    width: 1rem;
  }
}

.lzui-modal__close {
  &:hover,
  &:active,
  &:focus {
    @apply bg-primary text-white;
    transform: rotate(90deg);
  }
}

.lzui-modal__help {
  top: 1em;
  z-index: 1;
}

.lzui-modal {
  background: rgba(0, 0, 0, 0.67);
  bottom: 0;
  display: block;
  left: 0;
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-modal;

  @media print {
    display: none;
  }

  .lzui-modal__wrapper {
    padding: $sp-m 0;
    @include breakpoint(medium) {
      @include flexbox();
      @include flexjustify(center);
      @include flexalign(flex-start);
      height: 100%;
    }
  }

  .lzui-modal__dialog {
    @apply border-base bg-focus border-2;
    border-radius: $border-radius;
    margin: $sp-m;
    overflow: visible;
    position: relative;
    transform: none;
    transition: transform 0.2s;

    @include breakpoint(medium) {
      margin: $sp-l $sp-m;
    }

    &:focus {
      box-shadow: 0 0 8px rgba(102, 175, 233, 0.3);
    }

    > header {
      @apply border-base border-b;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      margin: 0 1rem;
      padding: 1rem 0;

      h2 {
        font-size: 1.2rem;
        margin: 0;
        padding-right: 3rem;
      }
    }

    footer {
      margin: 0;
      padding: 1rem 1rem 0;
      text-align: center;
      a,
      button,
      input {
        margin: 0 0 0.5rem 0.5rem;
      }
      form {
        display: inline-block;
      }
    }

    .lzui-modal__body {
      overflow: visible;
      padding: 1rem;

      .lzui-modal__header-link {
        font-size: $fs-s;
        position: absolute;
        right: 2rem;
        text-align: right;
        top: 23px;
      }

      p {
        text-shadow: inherit;
      }

      p.white {
        color: white;
      }

      .tabs-content {
        display: inline-block; // avoids problems with small resolutions in modals
      }
    }
  }

  .page-content {
    min-height: 0; // avoids problems with tabbed layouts in modals
  }
}

.lzui-modal--confirmation {
  text-align: center;

  .lzui-modal__dialog {
    padding: 0;

    header {
      h2 {
        padding-right: 0;
      }
    }
  }

  .lzui-modal__close {
    // display: none;
  }
}

.lzui-modal[aria-hidden='true'] {
  display: none;

  .lzui-modal__dialog {
    transform: translateY(-50%);
    transition-delay: 0.15s;
  }
}

@include breakpoint(medium) {
  .lzui-modal--small {
    .lzui-modal__dialog {
      max-width: 350px;
      width: 50%;
    }
  }

  .lzui-modal--medium {
    .lzui-modal__dialog {
      max-width: 550px;
      width: 75%;
    }
  }

  .lzui-modal--large {
    .lzui-modal__dialog {
      max-width: 1000px;
      width: 95%;
    }
  }

  .lzui-modal--xlarge {
    .lzui-modal__dialog {
      max-width: 1300px;
      width: 95%;
    }
  }
}

// resource insert modals
.lz-content-modal {
  text-align: left;

  @media #{$medium-up} {
    footer {
      button.btn--demoted {
        margin: 0.6rem 0;
        padding: 0;
      }
    }
  }
}

.lz-content__preview {
  @media #{$large-up} {
    margin-bottom: 1rem;

    .btn--demoted {
      margin: 0.6rem 0;
    }
  }
}

// special full screen modal
.lzui-sheet {
  @apply bg-white;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.25);
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
  transition: top 0.5s;

  &.lzui-sheet--expanded {
    top: 0;
    bottom: 0;
    height: auto;
    overflow: auto;
    top: 0;
    z-index: $z-modal + 1;
  }

  &.lzui-sheet--centered {
    @include flexbox;
    @include flexalign(center);

    > * {
      margin: auto;
    }
  }
}

.lzui-modal.standards-selector-modal {
  .lzui-modal__dialog {
    .lzui-modal__body {
      overflow: inherit;
      overflow-y: visible;
    }
  }
}
