.notification {
  @apply text-orange;
  svg {
    animation: ring 1s cubic-bezier(0, 0, 1, 0.53);
  }
}

.toast {
  position: fixed;
  z-index: $z-modal + 1;
  top: 1rem;
  left: 50%;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  transform: translateX(-50%);
  visibility: hidden;
  padding: $sp-s $sp-m;
  border-radius: calc($border-radius / 2);
  &.toast--show {
    visibility: visible;
    animation: fadeinToast 0.5s, fadeoutToast 0.5s 2.5s;
  }
  &.toast--success {
    @apply border-green border bg-green-100;
  }
}

@-webkit-keyframes fadeinToast {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 1rem;
    opacity: 1;
  }
}

@keyframes fadeinToast {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 1rem;
    opacity: 1;
  }
}

@-webkit-keyframes fadeoutToast {
  from {
    top: 1rem;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeoutToast {
  from {
    top: 1rem;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
