// cleans up readability of the Report font //
@mixin font-report-settings {
  font-kerning: normal;
}
@mixin font-report {
  @include font-report-settings;
  font-family: $font-report;
}

// centers vertically in container
@mixin vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// centers horizontally in container
@mixin horizontal-align {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// centers vertically/horizontally in container
@mixin center-align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// centers vertically/horizontally in container
@mixin flexbox-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

// flexbox prefixes
@mixin flexbox() {
  display: flex;
}

@mixin flexdirection($val) {
  flex-direction: $val;
}

@mixin flexwrap($val) {
  flex-wrap: $val;
}

@mixin flexalign($val) {
  align-items: $val;
}

@mixin flexjustify($val) {
  justify-content: $val;
}

@mixin alignself($val) {
  align-self: $val;
}

@mixin flex-val($values) {
  flex: $values;
}

@mixin order($val) {
  order: $val;
}

@mixin ol-list-style-parens {
  ol {
    counter-reset: list;
  }

  ol > li {
    list-style: none;
  }

  ol > li:before {
    content: counter(list) ')';
    counter-increment: list;
    margin-left: -1.2em;
    margin-right: .5em;
  }

  ol > li > ol {
    > li:before {
      content: counter(list, lower-alpha) ')';
    }
    > li > ol {
      > li:before {
        content: counter(list, lower-roman) ')';
      }
      > li > ol {
        > li:before {
          content: counter(list, decimal) ')';
        }
        > li > ol {
          > li:before {
            content: counter(list, lower-alpha) ')';
          }
          > li > ol {
            > li:before {
              content: counter(list, lower-roman) ')';
            }
            > li > ol {
              > li:before {
                content: counter(list, decimal) ')';
              }
            }
          }
        }
      }
    }
  }
}
