// --------------------------------
// Error messages across the app
// --------------------------------

.error {
  background: transparentize($red, 0.94);
  margin-left: -$sp-s;
  margin-right: -$sp-s;
  padding: $sp-s;

  input,
  select,
  textarea {
    border: 1px solid $red;
    box-shadow: 0 0 0 1px $red inset;
  }

  label {
    color: darken($red, 10%);

    span {
      font-weight: normal;
    }
  }
}

.error.error--page {
  border-radius: $border-radius;
  margin: $sp-m 0;
  padding: $sp-m;
  text-align: center;

  h2 {
    color: darken($red, 10%);
    font-size: $fs-l;
    margin: 0;
  }

  p {
    font-size: $fs-s;
    margin: 0;
  }
}
