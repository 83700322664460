// ---------------------------------------
// Display of collections via breadcrumbs
// ---------------------------------------

.tree-list {
  font-size: 0.875rem;
  list-style: none;
  padding-left: 1rem;

  .icon {
    float: left;
  }

  .tree-item {
    display: block;

    > .tree-item__title {
      .icon {
        svg {
          fill: rgb(var(--primary-800));

          path {
            fill: rgb(var(--primary-800));
          }
          path[fill='#fff'] {
            fill: #fff !important;
          }
        }
      }
    }

    &.active {
      > .tree-item__title {
        @apply bg-base;

        .icon {
          svg {
            fill: rgb(var(--primary-500));

            path {
              fill: rgb(var(--primary-500));
            }
          }
        }

        a {
          @apply text-primary;
          padding: 0.7rem 0;
        }
      }
    }

    .tree-item__title {
      display: inline-block;
      padding: 0.7rem 0;
      vertical-align: top;
      width: 100%;

      a {
        @apply text-base;
      }
    }
  }
}

body.menu-push {
  #breadcrumb_nav,
  .collection,
  .edit-collection-bar {
    display: none;
  }
}

@include breakpoint(xlarge up) {
  body.menu-push {
    .in-collection {
      max-width: none;
    }

    .collection-page__sidebar {
      @include flex-val(1 0);
      @apply border-base border-r;
      overflow: auto;
    }

    .collection-page {
      @include flexbox;

      .collection-page__content,
      > .lesson-plan__content,
      > .resource__content {
        @include flex-val(3 0);
      }
    }
  }

  .in-collection.collection-page {
    @include flexbox;

    .collection-page__content {
      @include flex-val(1 0 100%);
    }
  }

  .collection-page__sidebar {
    @include flex-val(0 0 0);
    overflow-x: hidden;
    position: relative;

    .collection-page__sidebar-inner {
      height: 100%;
      overflow: scroll;
      position: absolute;
      width: 100%;
    }
  }
}

.collection__title {
  @apply text-gray;
  padding: 0 $sp-xs;

  h4 {
    display: inline-block;
    font-size: $fs-xs;
    margin-bottom: $sp-s;
    margin-left: $sp-xs;
    margin-top: $sp-s;
  }

  > a {
    float: right;
    margin-top: $sp-xs;
  }
}

@include breakpoint(large down) {
  .collection-page__sidebar {
    @apply bg-gray-50;
    display: none;
    width: 100%;
  }

  body.menu-push {
    .collection-page__sidebar {
      display: block;
      left: 0;
      position: relative;
    }
  }
}

.collection-page__sidebar.animate-push-right {
  transition: all 0.3s ease;
}

@media print {
  .collection-page__sidebar {
    display: none;
  }
}

.collection__list {
  padding-top: 2rem;

  .page-head__pretext {
    @apply text-primary;
  }
}
