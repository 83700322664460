// player vs editor styles
.lesson-player {
  .card__content-wrapper {
    @apply absolute;
  }
  .card__notes-wrapper {
    @apply sm:relative md:absolute;
  }

  .tools-container {
    width: 500px;
    height: auto;
  }
}

// not active fullscreen
.lesson-player:not([data-fullscreen]) {
  @apply mt-5 pb-4;

  .lesson-cards {
    @apply mb-12;
  }

  .lesson-card-wrapper {
    flex-basis: auto;
  }

  #lesson-cards-list {
    @apply h-full;
  }
}

body.is_full {
  .lesson-content.lesson-plan__lesson {
    @apply justify-start overflow-auto;
  }

  .lesson-plan__content {
    max-width: unset !important;
  }

  .lesson-title {
    display: none !important;
  }
}

body.is_full [data-fullscreen].lesson-player .lesson-content {
  height: unset;
  overflow: visible;
  align-items: unset;
}

// active fullscreen
[data-fullscreen].lesson-player {
  @apply w-full px-14;

  #lesson-cards-list {
    max-width: 90vw !important;
  }

  .lesson-player-dual-deck #lesson-cards-list  {
    max-width: 100vw !important;
  }
}

// not active notes
.lesson-player:not([data-active-notes]) {
  .lesson-card-wrapper {
    @apply rounded-2xl;
  }

  .lesson-card-item-contents {
    @apply rounded-2xl;
  }

  .lesson-card-title {
    @apply rounded-t-2xl;
  }

  .lesson-notes {
    @apply h-0 opacity-0;
  }

  .card__content-wrapper {
    @apply md:rounded-b-2xl;
  }
}

// active notes
[data-active-notes].lesson-player {
  .lesson-card-wrapper {
    @apply rounded-t-2xl md:rounded-none md:rounded-l-2xl;
  }

  .lesson-card-item-contents {
    @apply rounded-t-2xl md:rounded-none md:rounded-l-2xl;
  }

  .lesson-card-title {
    @apply rounded-t-2xl md:rounded-t-none md:rounded-tl-2xl;
  }

  .lesson-notes {
    @apply rounded-b-xl sm:h-auto md:mt-0 md:rounded-b-none;
  }

  .card__content-wrapper {
    @apply md:rounded-bl-2xl;
  }
}

// not active sidebar
.lesson-player:not([data-active-sidebar]) {
  .lesson-thumbs {
    @apply w-0 opacity-0;
  }
}

// active sidebar active sidebar
[data-active-sidebar].lesson-player {
  .lesson-thumbs {
    @apply w-56 opacity-100;
  }

  .lesson-thumbs > ul {
    @apply pr-8 #{!important};
  }
}

[data-fullscreen][data-active-notes].lesson-player {
  .lesson-card-item-contents {
    @apply md:mr-48 lg:mr-80;
  }
}

[data-active-sidebar][data-fullscreen].lesson-player {
  .lesson-thumbs {
    @apply z-75;
  }

  .lesson-cards {
    @apply pl-2;
  }
}

[data-active-notes].lesson-player:not([data-fullscreen]) {
  .lesson-cards {
    @apply mr-0 md:mr-48 lg:mr-80;
  }

  #lesson-cards-list {
    @apply mx-auto;
  }

  .lesson-notes {
    @apply -mr-0 opacity-100 md:-mr-48 lg:-mr-80;
  }
}

// active annotations
[data-active-annotations].lesson-player {
  .lesson-content {
    @apply 3/2xl:pl-0 pl-16;
  }
}

.lesson-content .lesson-thumbs {
  max-height: calc(100vh - 7rem);
}
.lesson-content .lesson-thumbs:after {
  @apply pointer-events-none absolute top-0 left-0 w-full;
  height: calc(100% + 1px);
  content: '';
  background-image: linear-gradient(
    rgba(var(--neutral-200), 1),
    rgba(var(--neutral-200), 0) 1.25rem,
    rgba(var(--neutral-200), 0) calc(100% - 2em),
    rgba(var(--neutral-200), 1) calc(100% + 1px)
  );
}

@media print {
  .lesson-player {
    .lesson-card-wrapper {
      break-inside: avoid;
    }

    .lesson-card-content li {
      @apply h-full w-full;
    }

    .lesson-card-content .card__content-wrapper {
      @apply h-auto w-full;
    }

    .lesson-card-item-contents .card__content {
      @apply h-full w-full;
    }

    .lesson-card-content .card-deck-list-item-grid {
      @apply h-auto max-w-full;
    }

    .lesson-notes {
      @apply flex flex-col;
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .card__notes-wrapper {
        @apply h-full;
      }
    }

    .card__notes-head {
      @apply h-full;
    }
  }
}

#presenter-view .lesson-thumbs > ul {
  height: 100%;
  padding-bottom: 6rem !important;
}