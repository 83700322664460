// These are just overrides for what exists in LZ styling today

#live-learn {
  .icon svg {
    margin: 0;
  }

  .lesson-nav-item {
    text-shadow: 0 1px 1px rgba(black, 0.15);
  }

  #assignment-cards {
    @apply mt-0 #{!important};
  }

  #assignment .lp-deck__head,
  #assignment .embed-lp-deck__head {
    background: transparent;
    top: auto;

    .h3 {
      color: white;
    }
    .small {
      color: tw-color('blue', 200) !important;
    }
  }

  .lp-deck__full-screen,
  .slideshow__icon--prev,
  .slideshow__icon--next {
    visibility: hidden;
    pointer-events: none;
  }

  .card__content-wrapper .card__resource-contents {
    @apply bg-focus rounded-2xl;
  }
}

// New utility classes -- we should add these to Tailwind?
.triangle-top-down {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -1em;
    border-width: 0.5em;
    border-style: solid;
    border-color: rgb(var(--primary-100)) transparent transparent transparent;
    animation: slide-in ease 0.33s forwards;
  }
}

#toolbar-card-indicators:after {
  @apply pointer-events-none absolute left-0 top-0 h-full w-full;
  content: '';
  background-image: linear-gradient(
    90deg,
    transparent 2.5rem,
    rgba(var(--primary-700), 1) 2.5rem,
    rgba(var(--primary-700), 0) 4rem,
    rgba(var(--primary-700), 0) calc(100% - 4rem),
    rgba(var(--primary-700), 1) calc(100% - 2.5rem),
    transparent calc(100% - 2.5rem)
  );
}

#toolbar-card-indicators::-webkit-scrollbar {
  height: 0.25em;
}

#toolbar-card-indicators::-webkit-scrollbar-track {
  @apply bg-primary-900;
  border-radius: 0.5em;
}

#toolbar-card-indicators::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  @apply bg-primary-accent;
}
