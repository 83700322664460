.lz-standards {
  @apply text-muted inline-flex flex-row flex-wrap gap-2;
  padding: 0 0.125em;

  .cke_editable & {
    @apply border-base bg-base border;
    border-radius: 0.25em;
    display: inline-block;
    height: auto;
  }
}

.lz-standard {
  @apply text-primary-500 bg-neutral-300 hover:bg-neutral-400 hidden flex-wrap items-center justify-center whitespace-nowrap rounded-lg px-2 text-xs font-bold;

  .listable-editor & {
    display: inline-block;
  }

  .cke_editable & {
    @apply border-base bg-focus;
    display: inline-block;
    vertical-align: middle;
  }
}

.lz-standard-row {
  display: none;
  .listable-editor & {
    display: table-row;
  }
}
.standards-coverage-table {
  thead th a {
    text-overflow: ellipsis;
    min-width: 10ch;
    overflow: hidden;
    display: inline-block;
  }
  td div.icon {
    margin-left: calc(50% - 0.5rem);
  }
}

@for $initiative_id from 0 through 55 {
  html.standards-on main.initiative-#{$initiative_id} {
    span.initiative-#{$initiative_id} {
      display: inline-flex;
    }
    tr.initiative-#{$initiative_id} {
      display: table-row;
    }
  }
}

.cke_widget_wrapper:hover > .cke_widget_element {
  outline-color: tw-color('blue', 500) !important;
}

.build-code.lz-standard {
  @apply bg-neutral-400 hover:bg-neutral-500;
}

.building-towards-code {
  &:before {
    content: '→';
    border-right: 1px solid;
    display: inline-block;
    line-height: 0.85;
    margin-right: 0.15em;
  }
}

.building-on-code {
  &:after {
    content: '→';
    border-left: 1px solid;
    display: inline-block;
    line-height: 0.85;
    margin-left: 0.15em;
  }
}

// tooltip overrides
.has-tip.common-core-tip {
  @apply border-base border text-base;
  border-radius: 3px;
  margin-bottom: 0.25rem;
  padding: 0 5px;
  transition: all 0.1s ease-in;

  &:hover {
    @apply border-base-hover text-base-hover;
  }
}
