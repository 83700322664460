.google-classroom-link-course {
  @include flexbox();
  @include flexdirection(column);
  @include flexalign(center);
  position: relative;
  border-radius: $border-radius;

  .google-classroom-title {
    @apply text-pink;
    font-size: 1.5rem;
    font-weight: $font-weight--semibold;
  }

  .google-classroom-content {
    @apply text-base;
    font-size: 0.8125rem;
    font-weight: $font-weight--semibold;
  }

  .google-classroom-link-course-close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.google-classroom-notice {
  @include flexbox-center();
  position: relative;
  border-radius: $border-radius;

  &.google-classroom-notice--success {
    @apply bg-green-100;
  }

  .google-classroom-notice-close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

// Dark tooltip triangles
.tooltip.google-dark-tooltip {
  @apply bg-gray-700 text-white;
}

.tooltip.google-dark-tooltip::before {
  border-bottom-color: rgb(var(--gray-700));
}

.tooltip.right.google-dark-tooltip::before {
  border-bottom-color: transparent;
  border-right-color: rgb(var(--gray-700));
}

.tooltip.left.google-dark-tooltip::before {
  border-bottom-color: transparent;
  border-left-color: rgb(var(--gray-700));
}

.tooltip.top.google-dark-tooltip::before {
  border-bottom-color: transparent;
  border-top-color: rgb(var(--gray-700));
}

// Error tooltip
.tooltip.google-error-tooltip {
  @apply bg-pink-700 text-white;
}

.tooltip.google-error-tooltip::before {
  border-bottom-color: rgb(var(--pink-700));
}

.tooltip.right.google-error-tooltip::before {
  border-bottom-color: transparent;
  border-right-color: rgb(var(--pink-700));
}

.tooltip.left.google-error-tooltip::before {
  border-bottom-color: transparent;
  border-left-color: rgb(var(--pink-700));
}

.tooltip.top.google-error-tooltip::before {
  border-bottom-color: transparent;
  border-top-color: rgb(var(--pink-700));
}

// Warn tooltip triangles
.tooltip.google-warn-tooltip {
  @apply text-white;
  background: $amber;
}

.tooltip.google-warn-tooltip::before {
  border-bottom-color: $amber;
}

.tooltip.right.google-warn-tooltip::before {
  border-bottom-color: transparent;
  border-right-color: $amber;
}

.tooltip.left.google-warn-tooltip::before {
  border-bottom-color: transparent;
  border-left-color: $amber;
}

.tooltip.top.google-warn-tooltip::before {
  border-bottom-color: transparent;
  border-top-color: $amber;
}

.google-exclamation svg {
  fill: rgb(var(--pink-700));
}

.border-red {
  border-color: $red;
}

.google-warning {
  color: $amber;
}

.google-unlink {
  color: $red;
}

.google-share-upgrade-link {
  @apply text-pink;
  text-decoration: underline;
}

.google-classroom-student-search {
  .google-classroom-student-search__input {
    border-radius: 25px;
  }

  .google-classroom-student-search__icon {
    @include flexbox-center();
    border-radius: 25px;
    width: 30px;
    height: 30px;
    top: 4px;
    right: 4px;
  }

  .google-classroom-student-search__list {
    max-height: 300px;
    overflow-y: auto;
  }
}

.google-classroom-grade-passback-status-indicator {
  width: 40px;
  height: 18px;
}

.student-load-more-action-list {
  @include flexbox();
  @include flexjustify(flex-end);
  @include flexalign(center);
}

.assign-to-google-accordion-action {
  @include flexbox();
  @include flexalign(center);
}

.google-classroom-associate-dropdown {
  .google-classroom-associate-dropdown__confirming {
    @include flexbox();
    @include flexdirection(column);
    @include flexalign(center);

    > p {
      font-weight: $font-weight--semibold;
      @apply text-pink-700;
    }

    a {
      font-weight: $font-weight--semibold;
    }
  }
}

.google-course-dropdown {
  &::-webkit-scrollbar {
    @apply w-2;
  }
  &::-webkit-scrollbar-track {
    @apply bg-neutral-400 rounded-2xl;
  }
  &::-webkit-scrollbar-thumb {
    @apply bg-neutral-600 rounded-2xl transition-colors;
  }
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-neutral-700;
  }
}