//
//  Grid, Breakpoints, Spacing
//  ----------------------------------------------------------------------------
//

// Default whitespace unit
$space: 1rem;

// Breakpoints
$breakpoint-mobile: 32em; // 512px
$breakpoint-desktop: 48em; // 768px

// Grid
$grid-max-width: 60rem;
$grid-gutter: 1.5 * $space;

// Cards
$card__min-height: 20rem;

//
//  UI Sizing. Used for buttons and forms.
//  ----------------------------------------------------------------------------
//

$ui-height: 2.5rem;
$ui-height--small: 2rem;
$ui-height--big: 3.25rem;
$ui-height--xlarge: 4.5rem;

//
//  Typographic Defaults
//  ----------------------------------------------------------------------------
//

$font-color--normal: tw-color('primary', 800);
$font-color--strong: black;
$font-color--weak: rbga(var(--neutral-700), 0.75);
$font-weight--normal: 400;
$font-weight--semibold: 600;
$font-weight--bold: 700;

//
//  Typefaces Used.
//  ----------------------------------------------------------------------------
//
//  Used for font-family. See _type.scss for implementation details.
//
// For 3rd party usage, Report may be purchased here https://www.fontspring.com/fonts/typodermic/report
@font-face {
  font-family: 'Report';
  src: url('~fonts/report-tracked-regular.woff2') format('woff2'),
    url('~fonts/report-tracked-regular.woff') format('woff'), url('~fonts/report-tracked-regular.ttf') format('ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Report';
  src: url('~fonts/report-tracked-bold.woff2') format('woff2'), url('~fonts/report-tracked-bold.woff') format('woff'),
    url('~fonts/report-tracked-bold.ttf') format('ttf');
  font-weight: bold;
}

$font-nunito-sans: 'Nunito Sans', 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
$font-report: 'Report', 'Nunito Sans', 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
$font-default: $font-nunito-sans;
$font-fancy: $font-report;
$font-monospace: 'Source Code Pro', monospace;

@font-face {
  font-family: 'Hellix';
  src: url('~fonts/Hellix-Regular.woff2') format('woff2'), url('~fonts/Hellix-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Hellix';
  src: url('~fonts/Hellix-Bold.woff2') format('woff2'), url('~fonts/Hellix-Bold.woff') format('woff');
  font-weight: bold;
}

//
//  Typographic Scale.
//  ----------------------------------------------------------------------------
//
//  Used for font-size & line-height. See _type.scss for implementation details.
//

$fs-xxxxl: 2.4rem;
$lh-xxxl: 1.25; /* h1             40px */
$fs-xxxl: 2rem;
$fs-xxl: 1.953rem;
$lh-xxl: 1.25; /* h2             32px */
$fs-xl: 1.53rem;
$lh-xl: 1.25; /* h3             28px */
$fs-l: 1.25rem;
$lh-l: 1.6; /* h4             24px */
$fs-m: 1rem;
$lh-m: 1.6; /* baseline,p,h6  16px */
$fs-s: 0.875rem;
$lh-s: 1.4; /* .small         14px */
$fs-xs: 0.8125rem;
$lh-xs: 1.3; /* .smaller       13px */
$fs-xxs: 0.75rem;
$lh-xxs: 1.25; /* .smallest      12px */

$fs-xl-em: 1.53em; /*   28px */
$fs-l-em: 1.25em; /*   24px */

//
//  Spacing Scale.
//  ----------------------------------------------------------------------------
//
//  Used for margin & padding.
//

$sp-n: 0;
$sp-xxs: 0.125rem;
$sp-xs: 0.25rem;
$sp-s: 0.5rem;
$sp-m: 1rem;
$sp-l: 1.5rem;
$sp-xl: 3rem;
$sp-xxl: 8rem;
$sp-xxxl: 16rem;

//
//  Layers
//  ----------------------------------------------------------------------------
//
//  Used for z-index. See _positions.scss for implementation details.
//

$z-below: -1;
$z-base: 1;
$z-dropdown: 200;
$z-navigation: 800;
$z-modal: 1000;
$z-max: 2147483647;

//
// Borders
//

$border-color: tw-color('primary', 200);
$border-width: 1px;
$border-radius: 0.325rem;
