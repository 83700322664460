.file-upload {
  @include flexbox();
  @include flexdirection(column);

  .file-upload__buttons {
    @include flexbox();
    @include breakpoint(small down) {
      @include flexdirection(column);
    }
  }
  .file-upload__button {
    width: fit-content;
  }
  .file-upload__download {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 90%;
  }
  .file-drop-pane {
    .fsp-drop-pane__container {
      padding: 0 $sp-m;
      background: rgb(var(--green-100)) !important;
      border: 1px dashed rgb(var(--green-500)) !important;
      transition: all 0.2s;
      margin-left: $sp-s;
    }
    .fsp-drop-pane__text {
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    @include breakpoint(small down) {
      margin-top: $sp-s;
      .fsp-drop-pane__container {
        padding: $sp-m;
        margin: 0;
      }
    }
  }
  .file-upload__inline-container {
    display: block;
    height: 0;
    transition: height 0.2s ease;

    &.open {
      height: 20rem;
    }

    .fsp-picker--inline {
      width: 100%;
    }
  }

  .file-upload__remove {
    @apply bg-gray text-white;
    padding: 0.15rem 0.25rem;
    cursor: pointer;
    border-radius: 50%;
    margin-right: $sp-xs;
    transition: all 0.2s;
    .icon-s {
      width: 0.8rem;
      height: 0.8rem;
    }
    svg {
      margin: 0;
      width: 0.8rem;
      vertical-align: -0.1rem;
    }
    &:hover,
    &:active {
      @apply bg-gray-500;
    }
    span:last-child {
      position: absolute;
      top: -99999px;
      left: -99999px;
    }
  }
  .file-upload__preview {
    margin-bottom: $sp-s;
    width: 100%;
  }
}

div > .fsp-picker-holder {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $z-max;
  > .fsp-notifications__container {
    top: 200px;
  }
}

.filestack-upload {
  @apply bg-white p-6 rounded-xl shadow;
}

.fsp-modal {
  @apply bg-white h-full;
  border-radius: 16px !important;
  .fsp-header {
    display: none;
  }
  .fsp-modal__body {
    @apply bg-white;
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
  .fsp-modal__sidebar {
    @apply bg-neutral-300;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .fst-sidebar {
    border-top-left-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
  }
}

.fsp-modal__sidebar {
  @apply bg-neutral-300;

  .fsp-source-list {
    .fsp-source-list__label {
      font-size: 13px;
      font-weight: 500;
    }
.fsp-source-list__item--active {
  @apply bg-white;
}
  }
}

.fsp-content {
  height: 100% !important;

  .fsp-source-auth__wrapper{
    @apply bg-white pt-0;
  }

  .fsp-drop-area-container {
    @apply bg-white h-full;

    .fsp-drop-area:hover {
      @apply shadow-none;
    }

    .fsp-drop-area {
      @apply border-neutral-400 border-dashed rounded-2xl bg-base;
    }
  }
}

.file-upload__inline-container {
  .fsp-picker-holder {
    position: relative;
    .fsp-source-auth__wrapper {
      // otherwise filestack adds double icons here which is unnecessary
      .fsp-icon--auth.fsp-icon--googledrive {
        display: none;
      }
    }
  }

  > .fsp-notifications__container {
    top: 200px;
  }
}

// ckeditor filestack upload
#ckeditor-upload-button {
  @extend .btn;
  @extend .btn--green;
}

#ckeditor-inline-upload.no-drag-drop .fsp-drop-area__subtitle.fsp-text__subheader {
  // ckeditor modal interrupts filestack default drag and drop behavior
  display: none;
}
