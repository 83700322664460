// -----------------------------------------------------------------------------
// Comments on Lesson Plans and Assignments
// -----------------------------------------------------------------------------

.lzui-tile {
  .comment {
    textarea.form-control {
      height: auto;
    }
    .comment__avatar svg {
      height: auto;
    }
    .comment__first-level {
      padding: $sp-m 0;
      background: darken(tw-color('gray', 50), 3%);
      border: 0;
      border-bottom: 1px solid darken(tw-color('gray', 50), 20%);
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
    .comment__second-level {
      @apply bg-gray-50;
      border-bottom: 1px solid darken(tw-color('gray', 50), 20%);
      position: relative;
      border-radius: 0;
      &:last-child {
        border: 0;
      }
    }
    .comment__first-level,
    .comment__second-level {
      transition: background 1s ease;
    }
    .comment__content {
      overflow: hidden;
      p:last-child {
        margin-bottom: 0;
      }
      .comment__edit-form {
        @apply border border-gray-200 bg-white;
        position: relative;
        z-index: $z-modal + 1;
        border-radius: $border-radius;
        padding: 1rem;
        overflow: auto;
      }
    }
    .comment__date {
      display: block;
      @include breakpoint(medium) {
        display: inline;
        &:before {
          content: '\2022';
          margin: 0 0.4rem 0 0.2rem;
        }
      }
    }
    .comment--highlight {
      @apply bg-yellow-50;
    }
    .comment__assignment {
      padding: $sp-m 0;
      margin: 0;
    }
  }
}

.grading-feedback {
  flex-flow: row wrap;
  align-items: flex-start;
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }

  &__comments {
    flex: 3;
    margin-left: 0.5rem;
    @media (max-width: 768px) {
      margin: 0.5rem 0 0 0;
      width: 100%;
    }
  }
}
