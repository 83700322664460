.in-collection {
  max-width: 80rem;
  .tabs-content {
    padding-right: $sp-l;
  }

  @include breakpoint(xlarge) {
    @apply max-w-screen-2xl;
    display: flex;
    margin: 0 auto;
    > .lesson-plan__content,
    > .resource__content {
      flex: 3 0;
    }
    > .collection {
      flex: 1 0;
    }
  }
}

.collection {
  @apply bg-neutral-50 overflow-y-auto;
  width: 100%;
  @include breakpoint(xlarge) {
    @apply border-base bg-base border-l border-r;
    position: relative;
    width: 25%;
  }
}

@mixin collapsed-view {
  width: 100%;
  max-width: none;
  padding: 0;
  > a {
    @apply border-base border-t;
    display: block;
    text-align: center;
    padding: 1rem;
    .icon {
      transition: all 0.2s ease;
      display: inline-block;
    }
    &.expanded {
      .icon {
        transform: rotate(90deg);
      }
      + .collection__list {
        display: block;
        padding: $sp-m;
      }
    }
  }
  > .collection__list {
    display: none;
    max-width: 58rem;
    margin: 0 auto;
  }
}

#lesson-plan.in-collection.tool-open {
  max-width: none;
  flex-wrap: wrap;
  .lp-deck {
    max-width: none;
  }
  .collection {
    @apply bg-base;
    width: 100%;
    position: inherit;
    border: none;
    flex: unset;
  }
  .collection__content {
    @include collapsed-view;
    position: initial;
    height: initial;
  }
}

.collection__content {
  @include collapsed-view;

  @include breakpoint(xlarge) {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 $sp-m $sp-m;
    > a {
      display: none;
    }
    .collection__list {
      display: block;
      margin: 0;
      max-width: unset;
      > h3 {
        margin-top: 0;
      }
      > h4 {
        margin-bottom: 0;
      }
    }
  }

  @media print {
    display: none;
  }
}
