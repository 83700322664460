/*
  These styles were moved from Vue components to here to resolve wepback compilation errors.
  The webpack mini-css-extract-plugin doesn't know how to order Vue component styles in some situations.
  The eventual solution is to just convert everything to Tailwind.
*/

/*** Moved from VideoPlayer.vue */
div.video-player {
  .w-playbar-wrapper canvas {
    background-color: transparent;
  }

  /* Resolves a bug with Wistia toolbars flickering when scaled */
  /* !important needed to override inline styles set by JavaScript */
  .w-control-bar {
    opacity: 1 !important;
    transform: translate3d(0px, 0px, 0px);
  }
}

/*** Moved from LessonCardNotes.vue */
div.lesson-notes {
  background-color: rgb(245 248 255 / var(--tw-bg-opacity));
}

@media screen {
  div.lesson-notes .card__notes-wrapper {
    border: none;
    background-color: unset;
  }
}

div.lesson-notes .card__notes-wrapper .card__notes-head {
  border-color: rgba(30 26 101 / 0.55);
}

/*** Moved from LessonCard.vue */
.lesson-card-item {
  .lesson-card-content .slide-viewer-feature {
    width: 100%;
    height: auto;
  }

  .card__resource-contents {
    @apply flex items-center justify-center;
  }

  .lesson-card-content > h2 {
    align-self: center;
  }

  .lesson-card-content > div {
    width: 100%;
  }

  .lesson-card-content .card__content-wrapper {
    border: none;
    padding-bottom: 0;
  }
  .lesson-card-title {
    background-color: rgb(244 246 254 / 0.925);
    font-size: 1.33em;
  }
  .card__content-wrapper .btn--pill {
    top: -1.5rem !important;
  }
  .lesson-card-content .card-deck-list-item-grid {
    height: 100%;
  }
  @media print {
    .lesson-card-item-contents .card__content {
      background: none;
    }

    .lesson-card-content .card-deck-list-item-grid {
      overflow: auto;
      max-height: none;
    }
    .lesson-card-item-contents #slide-viewer-feature {
      height: 100% !important;
      transform: none !important;
      width: 100% !important;
      display: flex !important;
    }
  }
}

/*** Moved from LessonContainer.vue */
.lesson-cards {
  .lesson-card-wrapper {
    flex-basis: auto;
  }

  .lesson-card-wrapper.fs-ss {
    margin-left: 11rem !important;
    width: calc(100% - 11rem) !important;
  }

  @media print {
    .lesson-card-wrapper .card__resource {
      height: 100% !important;
    }

    .lesson-card-wrapper {
      break-inside: avoid;
    }
  }
}

/*** Moved from PositionIndicatorStatusDot.vue */
button.bg-sky-300 {
  background-color: #7dd3fc !important;
}

button.hover\:bg-sky-200:hover {
  background-color: #bae6fd !important;
}

/*** Moved from LessonToolbarItem.vue */
.lesson-toolbar__button-wrapper {
  .lesson-toolbar__button.disabled {
    background-color: transparent;
    pointer-events: none;
  }

  .lesson-toolbar__button:hover,
  .lesson-toolbar__button.active {
    @apply bg-primary-accent;
  }
  .lesson-toolbar__button {
    @apply transition-all;
    border-radius: inherit;
  }

  .lesson-toolbar__button-menu {
    width: auto;
    bottom: 80px;
    left: 0;
    background-color: rgba(30, 26, 101, 0.95);
  }
}
